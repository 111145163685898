<template>
    <vue-json-to-csv
        :json-data="this.exportdata"
        :csv-title="
            'netenquiryreport' +
            '-' +
            startdatecsv +
            '-' +
            enddatecsv + '-' + selected_course
        "
    >
        <vs-button type="filled" color="dark"
            >Export CSV</vs-button
        >
    </vue-json-to-csv>
</template>
<script>
import axios from "axios";
import constants from "../../../constants.json";
// import vSelect from "vue-select";
import moment from "moment";
import VueJsonToCsv from "vue-json-to-csv";
export default {
        data() {
            return {
                
                exportdata: [],
            };
        },
        props: ["startdateepoch", "enddateepoch", "selected_course", "courseArray", "SelectTeam", "startdatecsv", "enddatecsv"],
        mounted() {
            this.getexportData();
        },
        methods: {
            getexportData() {
                // this.open = false;
                
                let obj = {
                    // course: "IIT-AI",
                    team: this.SelectTeam,
                    course: this.courseArray.join(),
                    start_date: this.startdateepoch,
                    end_date: this.enddateepoch,
                };
                const url = `${constants.MILES_CM_BACK}getNetEnquiriesByGroupingJoins`;
                // this.$vs.loading();
                axios
                    .get(url, {
                        params: obj,
                        headers: {
                            Authorization: `Bearer ${localStorage.userAccessToken}`,
                        },
                    })
                    .then((response) => {
                        
                        
                       this.exportdata =  response.data;
                        // this.$vs.loading.close();
                    })
                    .catch((error) => {
                        this.$vs.loading.close();
                        this.handleError(error);
                    });
            },

        },
        components: {
            VueJsonToCsv,
            moment
        },
}
</script>

<style></style>