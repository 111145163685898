<template>
    <div style="margin-top: 3%">
        <vs-table :data="campaign_stats">
            <template slot="header">
                <vs-row style="margin-bottom: 1%">
                    <vs-col vs-w="9">
                        <h3>Campaign Stats</h3>
                    </vs-col>
                    <!-- <vs-col vs-w="3" style="text-align: right">
                        <vue-json-to-csv
                            :json-data="campaign_stats"
                            :csv-title="'netenquiry campaign report'"
                        >
                            <vs-button size="small" color="dark"
                                >Export Campaign CSV</vs-button
                            >
                        </vue-json-to-csv>
                    </vs-col> -->
                </vs-row>
            </template>
            <template slot="thead">
                <vs-th> Campaign </vs-th>
                <vs-th> Total </vs-th>
                <vs-th> Unique </vs-th>
                <vs-th> Yet To Connect </vs-th>
                <vs-th> Cold </vs-th>
                <vs-th> Warm </vs-th>
                <vs-th> Hot </vs-th>
                <vs-th> Enrolled </vs-th>
                <vs-th> Invalid </vs-th>
                <vs-th> MHP </vs-th>
                <vs-th> New Enquiry </vs-th>
                <vs-th> Re-Enquiry </vs-th>
            </template>

            <template slot-scope="{ data }">
                <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                    <vs-td :data="data[indextr].name">
                        {{ data[indextr].name }}
                    </vs-td>

                    <vs-td :data="data[indextr].total">
                        <a><p @click="passDataToPopup(tr, 'Total')">{{ data[indextr].total }}</p></a>
                    </vs-td>

                    <vs-td :data="data[indextr].unduplicated">
                        <a><p @click="passDataToPopup(tr, 'Unduplicated')">{{ data[indextr].unduplicated }}</p></a>
                    </vs-td>

                    <vs-td :data="data[indextr].untouched">
                        <a><p @click="passDataToPopup(tr, 'Yet To Connect')">{{ data[indextr].untouched }}</p></a>
                    </vs-td>

                    <vs-td :data="data[indextr].cold">
                        <a><p @click="passDataToPopup(tr, 'Cold')">{{ data[indextr].cold }}</p></a>
                    </vs-td>

                    <vs-td :data="data[indextr].warm">
                        <a><p @click="passDataToPopup(tr, 'Warm')">{{ data[indextr].warm }}</p></a>
                    </vs-td>

                    <vs-td :data="data[indextr].hot">
                        <a><p @click="passDataToPopup(tr, 'Hot')">{{ data[indextr].hot }}</p></a>
                    </vs-td>

                    <vs-td :data="data[indextr].enrolled">
                        <a><p @click="passDataToPopup(tr, 'Enrolled')">{{ data[indextr].enrolled }}</p></a>
                    </vs-td>

                    <vs-td :data="data[indextr].invalid">
                        <a><p @click="passDataToPopup(tr, 'Invalid')">{{ data[indextr].invalid }}</p></a>
                    </vs-td>

                    <vs-td :data="data[indextr].mhp">
                        <a><p @click="passDataToPopup(tr, 'MHP')">{{ data[indextr].mhp }}</p></a>
                    </vs-td>
                    <vs-td :data="data[indextr].mhp">
                        <a><p @click="passDataToPopup(tr, 'New Enquiry')">{{ data[indextr].new_enquiry }}</p></a>
                    </vs-td>
                    <vs-td :data="data[indextr].mhp">
                        <a><p @click="passDataToPopup(tr, 'Re Enquiry')">{{ data[indextr].re_enquiry }}</p></a>
                    </vs-td>
                </vs-tr>
            </template>
        </vs-table>
        <vs-popup class="exed_campaign_stats"  style="z-index: 53000" :title="spoc_popup_title"
            :active.sync="campain_data_popup">
            <p><b>Spoc Count : {{popup_campain_data.length}}</b></p>
            <div>
                <vs-table max-items="10" pagination :data="popup_campain_data">
                    <template slot="header">
                        <vs-row style="margin-bottom: 1%">
                        </vs-row>
                    </template>
                    <template slot="thead">
                        <vs-th> identity </vs-th>
                        <vs-th> Name </vs-th>
                        <vs-th> Current Spoc </vs-th>
                        <vs-th> Source </vs-th>
                        <vs-th> Course </vs-th>
                        <vs-th> M Level </vs-th>
                        <vs-th> U Level </vs-th>
                        <vs-th> Enquiry Date </vs-th>
                        <vs-th> View Info </vs-th>
                    </template>

                    <template slot-scope="{ data }">
                        <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                            <vs-td :data="data[indextr].identity">
                                {{ data[indextr].identity }}
                            </vs-td>
                            <vs-td :data="data[indextr].person_name">
                                {{ data[indextr].person_name }}
                            </vs-td>

                            <vs-td :data="data[indextr].current_spoc">
                                {{ data[indextr].current_spoc }}
                            </vs-td>

                            <vs-td :data="data[indextr].source">
                                {{ data[indextr].source }}
                            </vs-td>

                            <vs-td :data="data[indextr].course">
                                {{ data[indextr].course }}
                            </vs-td>
                            <vs-td :data="data[indextr].level">
                                {{ data[indextr].level }}
                            </vs-td>
                            <vs-td :data="data[indextr].u_level">
                                {{ data[indextr].u_level }}
                            </vs-td>
                            <vs-td :data="data[indextr].enquiry_date">
                                {{ data[indextr].enquiry_date }}
                            </vs-td>
                            <vs-td v-if="data[indextr].mwb_id != null && data.mwb_id != ''">
                                <div @click="openPopupEmit(tr)">
                                    <vs-icon icon="info_outline" size="small" color="dark"></vs-icon>
                                </div>
                            </vs-td>
                            <vs-td v-else>
                            </vs-td>
                        </vs-tr>
                    </template>
                </vs-table>
            </div>
        </vs-popup>
        <vs-popup class="holamundo" id="yet_to_connect_width" :title="spoc_popup_title"
            :active.sync="yet_to_connect_popup">
            <p><b>Spoc Count : {{popup_campain_data.length}}</b></p>
            <div>
                <vs-table max-items="10" pagination :data="popup_campain_data">
                    <template slot="header">
                        <vs-row style="margin-bottom: 1%">
                        </vs-row>
                    </template>
                    <template slot="thead">
                        <vs-th> Name </vs-th>
                        <vs-th> Course </vs-th>
                        <vs-th> Source </vs-th>
                        <vs-th> Campaign </vs-th>
                        <vs-th> Enquiry Date </vs-th>
                    </template>

                    <template slot-scope="{ data }">
                        <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                            <vs-td :data="data[indextr].name">
                                {{ data[indextr].name }}
                            </vs-td>
                            <vs-td :data="data[indextr].course">
                                {{ data[indextr].course }}
                            </vs-td>
                            <vs-td :data="data[indextr].source">
                                {{ data[indextr].source }}
                            </vs-td>
                            <vs-td :data="data[indextr].campaign">
                                {{ data[indextr].campaign }}
                            </vs-td>
                            <vs-td :data="data[indextr].enquiry_date">
                                {{ data[indextr].enquiry_date }}
                            </vs-td>
                        </vs-tr>
                    </template>
                </vs-table>
            </div>
        </vs-popup>
    </div>
</template>

<script>
import moment from "moment";
import VueJsonToCsv from "vue-json-to-csv";
export default {
    props: ["campaign_stats", "raw_data"],
    components: {
        //   JsonCSV
        VueJsonToCsv,
    },
    data() {
        return {
            yet_to_connect_popup: false,
            campain_data_popup: false,
            popup_campain_data: [],
            source_mapping: {
                "Chak De CPA": "MAC Webinar",
                "CMA Alumni Speak": "MAC Webinar",
                "CMA Gully Boy": "MAC Webinar",
                "CPA Alumni Speak": "MAC Webinar",
                Discovery: "Discovery",
                Email: "Email",
                Facebook: "Facebook",
                google: "Google",
                "Incoming Call": "Incoming Call",
                "Incoming Call -SM": "Incoming Call",
                "Incoming Calls": "Incoming Call",
                "Incoming Calls -SM": "Incoming Call",
                IncomingCall: "Incoming Call",
                "Info Id -SM": "Info Id -SM",
                Instagram: "Instagram",
                IR: "IR Team",
                "JAVA-FS": "JAVA-FS",
                LeadSquared: "LeadSquared",
                Linkedin: "Linkedin",
                LiveChat: "Live Chat",
                "Live Chat": "Live Chat",
                Naukari: "Naukri Learning",
                Naukri: "Naukri Learning",
                "Naukri Learning": "Naukri Learning",
                None: "other",
                Organic: "Organic",
                "Organic -SM": "Organic -SM",
                Quora: "Quora",
                "Quora -SM": "Quora",
                SMS: "SMS",
                Test: "other",
                Testing: "other",
                undefined: "other",
                Verloop: "Chat",
                Chat: "Chat",
                "Voice SMS": "Voice SMS",
                Webinar: "Webinar - Registered",
                "Webinar - Registered": "Webinar - Registered",
                Website: "Website Form",
                "Website Chat": "Chat",
                "Website Form": "Website Form",
                "Website Search": "Website Form",
                "Website Whatsapp": "Website Whatsapp",
                "Wesbite Whatsapp": "Website Whatsapp",
                WhatsApp: "Website Whatsapp",
                YouTube: "YouTube",
                Google: "Google",
                Youtube: "YouTube",
                LinkedIn: "Linkedin",
                "Business Partner": "other",
                "Naukri-MR": "Naukri-MR",
                SquadIQ: "other",
                "Webinar-WAI": "Webinar - Registered",
                Wiley: "Wiley",
            },
            cm_level_mapping: {
                M6: "P1",
                M5: "P1",
                M4: "P3",
                "M4-": "P3",
                "M3+": "P1",
                M3: "P1",
                M2: "P3",
                M1: "P3",
                "M2 - Did not Visit & Postponed": "P3",
                L6: "P2",
                L5: "P2",
                L4: "P2",
                "L4-": "P3",
                "L3+": "P2",
                L3: "P2",
                L2: "P2",
                L1: "P3",
                "L2-": "P2",
            },
            spoc_popup_title: "",
        }
    },
    methods: {
        passDataToPopup(popup_spoc_data_row, selected_level) {
            // console.log("spoc_stats",this.campaign_stats,this.raw_data)
            this.spoc_popup_title = popup_spoc_data_row.name + ' ' + selected_level;
            let campain_distinct = [];
            let person_ids = [];
            this.popup_campain_data = [];
            let source_data = this.raw_data;
            for (let i = 0; i < source_data.length; i++) {
                let element = this.raw_data[i];
                let display_source = this.getDisplaySource(element.source);
                let selected_popup_source = this.getDisplaySource(popup_spoc_data_row.source);
                let level = this.getCMDisplayLevel(element.level);
                if (popup_spoc_data_row.name !== "All") {
                    if (selected_level === "Unduplicated") {
                        if (display_source === selected_popup_source && popup_spoc_data_row.name === element.campaign && !person_ids.includes(element.person_id)) {
                            // if(element.person_id !== null){
                            campain_distinct.push(element);
                            this.campain_data_popup = true;
                            // }
                        }
                        person_ids.push(element.person_id);
                    }
                    if (selected_level === "New Enquiry") {
                        let lead_added_timestamp = moment(element.lead_added_date).unix();
                        if (display_source === selected_popup_source && popup_spoc_data_row.name === element.campaign && element.person_id !== null && element.matched === 1 && lead_added_timestamp > element.enquiry_date_unix) {
                            campain_distinct.push(element);
                            this.campain_data_popup = true;
                        }
                    }
                    if (selected_level === "Re Enquiry") {
                        let lead_added_timestamp = moment(element.lead_added_date).unix();
                        if (display_source === selected_popup_source && element.person_id !== null && element.matched === 1 && lead_added_timestamp <= element.enquiry_date_unix) {
                            campain_distinct.push(element);
                            this.campain_data_popup = true;
                        }
                    }
                    if (selected_level === "Total") {
                        // console.log("display_source",display_source)
                        // console.log("selected_popup_source",selected_popup_source)
                        if (display_source === selected_popup_source && popup_spoc_data_row.name === element.campaign) {
                            campain_distinct.push(element);
                            this.campain_data_popup = true;
                        }
                    }
                    if (selected_level === "Warm" || selected_level === "Hot") {
                        if (display_source === selected_popup_source && selected_level === element.u_level && popup_spoc_data_row.name === element.campaign) {
                            // console.log("selected Level")
                            campain_distinct.push(element);
                            this.campain_data_popup = true;
                        }
                    }
                    if (selected_level === "Cold") {
                        if (display_source === selected_popup_source && (element.u_level === "Cold" || element.u_level === "cold" || element.u_level === "cold" || element.u_level == undefined || element.u_level == 'Busy/NL') && popup_spoc_data_row.name === element.campaign) {
                            campain_distinct.push(element);
                            this.campain_data_popup = true;
                        }
                    }
                    if (selected_level === "Enrolled") {
                        if (element.enrolled === 1 && display_source === selected_popup_source && popup_spoc_data_row.name === element.campaign) {
                            campain_distinct.push(element);
                            this.campain_data_popup = true;
                        }
                    }
                    if (selected_level === "Invalid") {
                        if (element.invalid_net_enquiry === 1 && display_source === selected_popup_source && popup_spoc_data_row.name === element.campaign) {
                            console.log("Invalid", element.source);
                            campain_distinct.push(element);
                            this.campain_data_popup = true;
                        }
                    }
                    if (selected_level === "MHP") {
                        if (element.mhp_status === 1 && display_source === selected_popup_source && popup_spoc_data_row.name === element.campaign) {
                            console.log("mhp check", element.source);
                            campain_distinct.push(element);
                        }
                    }
                    if (selected_level === "Yet To Connect") {
                        if (element.invalid_net_enquiry === 0 && element.matched === 0 && display_source === selected_popup_source && popup_spoc_data_row.name === element.campaign) {
                            campain_distinct.push(element);
                            this.yet_to_connect_popup = true;
                        }
                    }
                }
                if (popup_spoc_data_row.name === "All") {
                    if (selected_level === "Unduplicated") {
                        if (!person_ids.includes(element.person_id)) {
                            if (element.person_id !== null) {
                                campain_distinct.push(element);
                            }
                        }
                        person_ids.push(element.person_id);
                    }
                    if (selected_level === "New Enquiry") {
                        let lead_added_timestamp = moment(element.lead_added_date).unix();
                        if (element.person_id !== null && element.matched === 1 && lead_added_timestamp > element.enquiry_date_unix) {
                            campain_distinct.push(element);
                        }
                    }
                    if (selected_level === "Re Enquiry") {
                        let lead_added_timestamp = moment(element.lead_added_date).unix();
                        if (element.person_id !== null && element.matched === 1 && lead_added_timestamp <= element.enquiry_date_unix) {
                            campain_distinct.push(element);
                        }
                    }
                    if (selected_level === "Total") {
                        if (display_source === selected_popup_source && popup_spoc_data_row.name === element.campaign) {
                            campain_distinct.push(element);
                        }
                    }
                    if (selected_level === "P1" || selected_level === "P2" || selected_level === "P3") {
                        if (selected_level === level) {
                            campain_distinct.push(element);
                        }
                    }
                    if (selected_level === "Enrolled") {
                        if (element.enrolled === 1) {
                            campain_distinct.push(element);
                        }
                    }
                    if (selected_level === "MHP") {
                        if (element.mhp_status === 1) {
                            campain_distinct.push(element);
                        }
                    }
                    if (selected_level === "Yet To Connect") {
                        if (element.invalid_net_enquiry === 0 && element.matched === 0) {
                            campain_distinct.push(element);
                        }
                    }
                    if (selected_level === "Invalid") {
                        if (element.invalid_net_enquiry === 1) {
                            console.log("Invalid", element.source);
                            campain_distinct.push(element);
                        }
                    }
                }
            }
            // console.log("Yet to connect",campain_distinct);
            let unique = [];
            if (selected_level !== "Yet To Connect" && selected_level !== "Total" && selected_level !== "Invalid") {
                for (let i = 0; i < campain_distinct.length; i++) {
                    if (!unique[campain_distinct[i].identity]) {
                        this.popup_campain_data.push(campain_distinct[i]);
                        unique[campain_distinct[i].identity] = 1;
                    }
                }
            }
            if (selected_level === "Yet To Connect" || selected_level === "Total" || selected_level === "Invalid") {
                this.popup_campain_data = campain_distinct;
            }
            if (selected_level === "Yet To Connect") {
                this.campain_data_popup = false;
                this.yet_to_connect_popup = true;

            }
            if (selected_level !== "Yet To Connect") {
                this.campain_data_popup = true;
            }
        },
        openPopupEmit(payload) {
            this.openBigPopup(payload.mwb_id);
        },
        getDisplaySource(raw_source) {
            let source = this.source_mapping[raw_source];
            if (source === undefined || source === "") {
                source = "other";
            }
            return source;
        },
        getCMDisplayLevel(cm_level) {
            let cmLevel = this.cm_level_mapping[cm_level];
            if (cmLevel === undefined) {
                cmLevel = "cold";
            }
            return cmLevel;
        },
    }
};
</script>
<style>
.exed_campaign_stats .vs-popup {
  min-width: 80%;
}
</style>