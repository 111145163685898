<template>
  <div>
    <vx-card actionable class="cardx" title="Net Enquiries Source Report">
      <vs-row class="rows">
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2">
          <vs-button class="rounded-r-none p-3 md:px-8 md:py-3" @click="cmTeam('CM')" :color="colorx"
            :style="cmteamselectbuttoncolor" size="small" type="border">CM</vs-button>
          <vs-button class="rounded-l-none p-3 md:px-8 md:py-3" @click="exedTeam('ExEd')" :color="colorx"
            :style="exedteamselectbuttoncolor" size="small" type="border">ExEd</vs-button>
        </vs-col>
        <vs-col vs-w="3">
          <date-range-picker ref="picker" :opens="'right'" :locale-data="{
            firstDay: 1,
            format: 'DD-MMM-YYYY',
            customRangeLabel: 'Custom Range',
          }" :minDate="null" :maxDate="null" :singleDatePicker="false" :timePicker="false" :timePicker24Hour="true"
            :showWeekNumbers="false" :showDropdowns="false" :autoApply="false" v-model="dateRange"
            style="width: 250px; margin-right: 2%; margin-top: 0.3%"></date-range-picker>
        </vs-col>
        <!-- <vs-col vs-w="4" v-if="this.exedteam === 'ExEd'">
          <v-select
            label="Roles"
            :options="exed_courses"
            placeholder="Select EXed Course"
            v-model="exed_selected_course"
            style="width: 240px; z-index: 1000"
          ></v-select>
        </vs-col> -->
        <vs-col vs-w="3">
          <v-select label="Roles" :options="courses" placeholder="Select Course" v-model="selected_course"
            style="width: 240px; z-index: 1000"></v-select>

        </vs-col>
        <vs-col v-if="this.exportcsvshow !== false" vs-w="1">
          <vs-button @click="info_popup_active=true" color="dark" icon="info"></vs-button>
          <vs-popup class="holamundo" width="100%" title="Info" :active.sync="info_popup_active">
            <p><b>Total</b> : All enquiries within the time range</p>
            <p><b>Unique</b> : Excludes duplicates from Total</p>
            <p><b>Yet To Connect</b> : No Contact made at all. Net Enquiry which has not been converted to lead in the
              selected date range</p>
            <p><b>Enrolled</b> : Candidates who have enrolled who have atleast one Net Enquiry</p>
            <p><b>Invalid</b> : Net Enquiries which are marked as Invalid</p>
            <p><b>MHP</b> : Existing Leads which have an MHP date within the selected date range</p>
            <p><b>New Enquiry</b> : New Net Enquiries that came in the selected date range and converted to lead</p>
            <p><b>Re Enquiries</b> : Already existing lead who enquired again in the above date range (lead created date
              > start date in selecte date range)</p>
          </vs-popup>
        </vs-col>
        
        <vs-col vs-w="3" style="text-align: right">
          <vs-button color="dark" style="margin-left: 2%" type="filled" @click="generateReport()">Generate Report
          </vs-button>
        </vs-col>
      </vs-row>
      
      <vs-row style="margin-top: 2%" v-if="this.exportcsvshow !== false">
        <vs-col vs-w="2">
        </vs-col>
        <vs-col v-if="this.exportcsvshow !== false"  vs-w="3">
          <div v-if="SelectTeam == 'CM'" >
            <vs-dropdown vs-custom-content vs-trigger-click>
              <a class="a-icon" href.prevent>
                NE City
                <i class="material-icons">expand_more</i>
              </a>
              <vs-dropdown-menu class="dropdown-login customDropDown">
                <treeselect 
                  :value-consists-of="'LEAF_PRIORITY'"   
                  :multiple="true" value-format="object"   
                  :options="city_classification_list" 
                  placeholder="Select NE City" 
                  v-model="selected_city" />
              </vs-dropdown-menu>
            </vs-dropdown>
          </div>
        </vs-col>
        <vs-col v-if="this.exportcsvshow !== false"  vs-w="3">
          <div  v-if="SelectTeam == 'CM'" >
            <vs-dropdown vs-custom-content vs-trigger-click>
              <a class="a-icon" href.prevent>
                Spoc City
                <i class="material-icons">expand_more</i>
              </a>
              <vs-dropdown-menu class="dropdown-login customDropDown">
                <treeselect 
                  :value-consists-of="'LEAF_PRIORITY'"   
                  :multiple="true" value-format="object"   
                  :options="spoc_city_classification_list" 
                  placeholder="Select Spoc City" 
                  v-model="selected_spoc_city" />
              </vs-dropdown-menu>
            </vs-dropdown>
          </div>
        </vs-col>
        <vs-col vs-w="4" style="text-align: right" v-if="
          this.userid === 132 ||
          this.userid === 122 ||
          this.userid === 39 ||
          this.userid === 977 ||
          this.userid === 343 ||
          this.userid === 143 ||
          this.userid === 1344 ||
          this.userid === 1736 
        ">
          <vue-json-to-csv :json-data="exportdata" :csv-title="
            'netenquiryreport' +
            '-' +
            startdatecsv +
            '-' +
            enddatecsv +
            '-' +
            this.selected_course
          ">
            <vs-button type="filled" color="dark">Export CSV</vs-button>
          </vue-json-to-csv>
          <!-- <netEnquiryExportcsv 
                    :startdateepoch="startdateepoch"
                    :enddateepoch="enddateepoch"
                    :selected_course="selected_course"
                    :courseArray="courseArray"
                    :SelectTeam="SelectTeam"
                    :startdatecsv="startdatecsv"
                    :enddatecsv="enddatecsv"
                    ></netEnquiryExportcsv> -->
        </vs-col>
      </vs-row>
      <div v-if="open">
        <cm-team :source_stats="source_stats" :userid="userid" :raw_data="display_data" :startdatecsv="startdatecsv" :start_date="dateRange.startDate"
          :enddatecsv="enddatecsv" @source_changed="displayDetails" v-if="this.SelectTeam === 'CM'"></cm-team>
        <source-stats :source_stats="source_stats" :userid="userid" :raw_data="display_data"
          :startdatecsv="startdatecsv" :enddatecsv="enddatecsv" @source_changed="displayDetails"
          v-if="this.SelectTeam === 'ExEd'"></source-stats>
        <spoc-stats :spoc_stats="spoc_stats" :selected_source="selected_source" :raw_data="display_data" v-if="this.SelectTeam === 'ExEd'"></spoc-stats>
        <cm-spoc :raw_data="display_data" :selected_source="selected_source" :spoc_stats="spoc_stats"
          v-if="this.SelectTeam === 'CM'"></cm-spoc>
        <cm-campain :raw_data="display_data" :campaign_stats="campaign_stats" v-if="this.SelectTeam === 'CM'">
        </cm-campain>
        <campaign-stats :campaign_stats="campaign_stats"  :raw_data="display_data" v-if="this.SelectTeam === 'ExEd'"></campaign-stats>
      </div>
    </vx-card>
  </div>
</template>

<script>
import axios from "axios";
import constants from "../../constants.json";
import _ from "lodash";
import SourceStats from "../components/NetEnquiryReport/SourceStats";
import SpocStats from "../components/NetEnquiryReport/SpocStats.vue";
import CampaignStats from "../components/NetEnquiryReport/CampaignStats";
import DateRangePicker from "vue2-daterange-picker";
import CmTeam from "../components/NetEnquiryReport/CmTeam.vue";
import CmSpoc from "../components/NetEnquiryReport/CmSpoc.vue";
import CmCampain from "../components/NetEnquiryReport/CmCampain.vue";
import moment from "moment";
import vSelect from "vue-select";
import VueJsonToCsv from "vue-json-to-csv";
import netEnquiryExportcsv from "../components/NetEnquiryReport/netEnquiryExportcsv.vue";
import { Treeselect } from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import "vue-select/dist/vue-select.css";
import "vue-tree-halower/dist/halower-tree.min.css";
import { VTree, VSelectTree } from "vue-tree-halower";
export default {
  data() {
    return {
      selected_cities_list: [],
      selected_spoc_cities_list: [],
      selected_spoc_ids: [],
      selected_city_classifications: [],
      selected_city: [],
      selected_spoc_city: [],
      cities_list : [],
      city_classification_list: [
        {
          id: "All",
          label: "All",
          children: []
        }
      ],
      spoc_city_classification_list: [
        {
          id: "All",
          label: "All",
          children: []
        }
      ],
      info_popup_active: false,
      startdateepoch: "",
      enddateepoch: "",
      userid: "",
      colorx: "rgb(199, 42, 117)",
      cmteamselectbuttoncolor: [
        {
          background: "rgb(199, 42, 117)",
          color: "#ffffff",
          border: "1px solid rgb(199, 42, 117)",
        },
      ],
      exedteamselectbuttoncolor: [
        {
          background: "#ffffff",
          color: "rgb(199, 42, 117)",
          border: "1px solid rgb(199, 42, 117)",
        },
      ],
      open: false,
      courseArray: [],
      display_levels: ["Hot", "Warm", "Cold", "Enrolled", "New", "Invalid"],
      exed_courses: [
        // "IIML-PM",
        // "IIML-HR",
        // "IITR-BF",
        // "IIML-BA",
        // "IITR-DB(IIT-AI)",
        // "IITM-AA(IIT-AI)",
        // "IIT-AI(Only)",
        // "IITM-AA(Only)",
        // "IIMK-CX",
        // "IITM-FS",
        // "IITR-CC",
        // "IIMK-FT",
        // "IIML-AB",
        // "IIML-SH",
        // "IITJ-DE",
        // "IIML-SF",
        // "IIML-FA",
        "XLRI-HR",
        "XLRI-SH",
        "IIMI-BA",
        "IIMI-AA",
        // "XLRI-DM",
      ],
      cm_courses: ["CPA", "CMA","USP", "CFA", "FRM"],
      exed_selected_course: "",
      cm_selected_course: "",
      dateRange: {
        startDate: new Date(),
        endDate: new Date(),
      },
      courses: [],
      sources: [
        "Google",
        "Live Chat",
        "Organic",
        "Others",
        "Chat",
        "Email",
        "Facebook",
        "Meta",
        "SuperBot",
        "LMS",
        "Incoming Call",
        "Instagram",
        "IR Team",
        "LeadSquared",
        "Linkedin",
        "MAC Webinar",
        "Naukri Learning",
        "Naukri-MR",
        "other",
        "Quora",
        "SMS",
        "Voice SMS",
        "Webinar - Registered",
        "Website Form",
        "Website",
        "Website Whatsapp",
        "Whatsapp",
        "YouTube",
        "Wiley",
        "website",
      ],
      exed_level_mapping: {
        "Exam-Pass-Dropout": "cold",
        "Application Received": "hot",
        "Exam-Noshow-Dropout": "cold",
        "Exam-Fail-Dropout": "cold",
        "Exam-Fail": "cold",
        DND: "cold",
        "Exam-Pass": "hot",
        "Very Hot": "hot",
        Hot: "hot",
        "N/A": "cold",
        NULL: "cold",
        Warm: "warm",
        Cold: "cold",
        "CM-Yet-To-Connect": "cold",
      },
      cm_level_mapping: {
        M6: "P1",
        M5: "P1",
        "M3++": "P1",
        "M3+": "P1",
        M3: "P1",
        M4: "P2",
        M2: "P2",
        "M4-": "P3",
        M1: "P3",
        // "M7": "enrolled",
        // "M10": "enrolled",
        // "M7-": "enrolled",
        // "M9": "enrolled",
        // "M7X": "enrolled",
        // "M9-": "enrolled",
        // "M7+": "enrolled",
        // "M8": "enrolled",
        // "M8-": "enrolled",
        // "M2 - Did not Visit & Postponed": "P3",
        // L6: "P2",
        // L5: "P2",
        // L4: "P2",
        // "L4-": "P3",
        // "L3+": "P2",
        // L3: "P2",
        // L2: "P2",
        // L1: "P3",
        // "L2-": "P2",
      },
      source_mapping: {
        "website": "website",
        "-webinar": "website",
        "Chak De CPA": "MAC Webinar",
        "CMA Alumni Speak": "MAC Webinar",
        "CMA Gully Boy": "MAC Webinar",
        "CPA Alumni Speak": "MAC Webinar",
        Discovery: "Discovery",
        Email: "Email",
        Facebook: "Facebook",
        Meta: "Meta",
        SuperBot: "SuperBot",
        LMS: "LMS",
        google: "Google",
        "Incoming Call": "Incoming Call",
        "Incoming Call -SM": "Incoming Call",
        "Incoming Calls": "Incoming Call",
        "Incoming Calls -SM": "Incoming Call",
        IncomingCall: "Incoming Call",
        "Info Id -SM": "Info Id -SM",
        Instagram: "Instagram",
        IR: "IR Team",
        "JAVA-FS": "JAVA-FS",
        LeadSquared: "LeadSquared",
        Linkedin: "Linkedin",
        LiveChat: "Live Chat",
        "Live Chat": "Live Chat",
        Naukari: "Naukri Learning",
        Naukri: "Naukri Learning",
        "Naukri Learning": "Naukri Learning",
        None: "other",
        Organic: "Organic",
        "Organic -SM": "Organic -SM",
        Quora: "Quora",
        "Quora -SM": "Quora",
        SMS: "SMS",
        Test: "other",
        Testing: "other",
        undefined: "other",
        Verloop: "Chat",
        Chat: "Chat",
        "Voice SMS": "Voice SMS",
        Webinar: "Webinar - Registered",
        "Webinar - Registered": "Webinar - Registered",
        Website: "Website",
        "Website Chat": "Chat",
        "Website Form": "Website Form",
        "Website Search": "Website Form",
        "Website Whatsapp": "Website Whatsapp",
        "Wesbite Whatsapp": "Website Whatsapp",
        WhatsApp: "WhatsApp",
        YouTube: "YouTube",
        Google: "Google",
        Youtube: "YouTube",
        LinkedIn: "Linkedin",
        "Business Partner": "other",
        "Naukri-MR": "Naukri-MR",
        SquadIQ: "other",
        "Webinar-WAI": "Webinar - Registered",
        Wiley: "Wiley",
        "gupshup" : "Gupshup",
        "Gupshup" : "Gupshup"
      },
      source_stats: [],
      selected_source: [],
      spoc_stats: [],
      campaign_stats: [],
      raw_data: [],
      sorted_data: null,
      cmteam: "",
      exedteam: "",
      CM: false,
      EXED: false,
      selected_course: "",
      SelectTeam: "",
      startdatecsv: "",
      enddatecsv: "",
      exportcsvshow: false,
      exportdata: [],
      display_data: [],
    };
  },

  methods: {
    myprofile() {
      let url = `${constants.SERVER_API}getUserProfile`;
      axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${localStorage.userAccessToken}`,
          },
        })
        .then((response) => {
          let data = response.data.data;
          this.userid = data.id;
          //   console.log(this.userid);
        })
        .catch((error) => {
          this.handleError(error);
        });
    },

    cmTeam(team) {
      console.log(team);
      this.selected_course = "";
      this.courses = this.cm_courses;
      this.SelectTeam = team;
      this.source_stats = [];
      this.spoc_stats = [];
      this.campaign_stats = [];
      this.exportcsvshow = false;
      this.exedteamselectbuttoncolor = [
        {
          background: "#ffffff",
          color: "rgb(199, 42, 117)",
          border: "1px solid rgb(199, 42, 117)",
        },
      ];
      this.cmteamselectbuttoncolor = [
        {
          background: "rgb(199, 42, 117)",
          color: "#ffffff",
          border: "1px solid rgb(199, 42, 117)",
        },
      ];
      // this.CM = true;
      // this.EXED = false;
    },

    exedTeam(team) {
      // console.log(team);
      this.selected_course = "";
      this.courses = this.exed_courses;
      this.SelectTeam = team;
      this.source_stats = [];
      this.spoc_stats = [];
      this.campaign_stats = [];
      this.exportcsvshow = false;
      this.cmteamselectbuttoncolor = [
        {
          background: "#ffffff",
          color: "rgb(199, 42, 117)",
          border: "1px solid rgb(199, 42, 117)",
        },
      ];
      this.exedteamselectbuttoncolor = [
        {
          background: "rgb(199, 42, 117)",
          color: "#ffffff",
          border: "1px solid rgb(199, 42, 117)",
        },
      ];
      // this.CM = false;
      // this.EXED = true;
    },

    generateReport() {
      this.selected_spoc_city = [];
      this.selected_city = [];
      this.getData();
    },

    getData() {
      this.open = false;
      this.startdatecsv = moment(this.dateRange.startDate).format(
        "DD-MMM-YYYY"
      );
      this.enddatecsv = moment(this.dateRange.endDate).format("DD-MMM-YYYY");
      let obj = {
        // course: "IIT-AI",
        team: this.SelectTeam,
        course: this.courseArray.join(),
        start_date: this.datatoTimestamp(this.dateRange.startDate),
        end_date: this.datatoTimestamp(this.dateRange.endDate),
      };
      const url = `${constants.MILES_CM_BACK}getNetEnquiriesByGroupingJoins`;
      this.$vs.loading();
      axios
        .get(url, {
          params: obj,
          headers: {
            Authorization: `Bearer ${localStorage.userAccessToken}`,
          },
        })
        .then((response) => {
          this.exportdata = JSON.parse(JSON.stringify(response.data));

          if (response.status === "error") {
            this.handleNotification(response);
            this.exportcsvshow = false;
            this.$vs.loading.close();
          } else {
            this.exportcsvshow = true;
            this.spoc_stats = [];
            this.campaign_stats = [];
            this.display_data = response.data;
            
            this.getNECityClassification(response.data);
            this.getCityClassificationSpocs(response.data);
            this.summariseBySources(this.display_data);
            this.open = true;
            this.$vs.loading.close();
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          this.handleError(error);
        });
    },

    getNECityClassification(spocs_data){
      this.cities_list  = [...new Set(spocs_data.map((item) => item.city)), ];
      console.log("cities list order",this.cities_list);
      this.city_classification_list[0].children = [];
      for (let i = 0; i < this.cities_list.length; i++) {
          const element = this.cities_list[i];
          if(element != null && element != ""){
            var obj = {
              id: element,
              label: element,
              children: [],
            };
            this.city_classification_list[0].children.push(obj);
          }
      }

      this.city_classification_list[0].children = this.sortNestedArrayAlphabetically(
        this.city_classification_list[0].children
      );
      console.log("getNECityClassification",this.city_classification_list);
      spocs_data.forEach(element => {
        this.city_classification_list[0].children.forEach(city => {
          if(element.city == city.label){
            if (!city.children.find(item => item.label === element.city_classification)) { //to remove city classification duplicates
              if(element.city_classification != null){
                var obj = {
                  id: element.city + "_" + element.city_classification, // combine city and ccf because x is in more than one city
                  label: element.city_classification,
                  children: [],
                };
                city.children.push(obj);
              }
            }
          }
        });   
      });
      this.city_classification_list[0].children.forEach(city => {
        city.children = this.sortNestedArrayAlphabetically(city.children);
      });  
      spocs_data.forEach(element => {
        this.city_classification_list[0].children.forEach(city => {
          city.children.forEach(ccf => {
            if (!ccf.children.find(item => item.id === element.spoc_id + "_" + element.city + "_" + element.city_classification)) { //To remove spoc id duplicates
              if(element.city + "_" + element.city_classification == city.label + "_" + ccf.label){   //To remove spoc id duplicates
                var obj = {
                  id: element.spoc_id + "_" + ccf.id, //To remove spoc id duplicates
                  // id: element.spoc_id,
                  label: element.current_spoc,
                  city: element.city,
                  city_classification: element.city_classification,
                  spoc_id : element.spoc_id
                };
                ccf.children.push(obj);
              } 
            }
          });  
        });
      });
    },

    getCityClassificationSpocs(spocs_data){
      this.cities_list  = [...new Set(spocs_data.map((item) => item.spoc_city)), ];
      console.log("cities list order",this.cities_list);
      this.spoc_city_classification_list[0].children = [];
      for (let i = 0; i < this.cities_list.length; i++) {
          const element = this.cities_list[i];
          if(element != null && element != ""){
            var obj = {
              id: element,
              label: element,
              children: [],
            };
            this.spoc_city_classification_list[0].children.push(obj);
          }
      }

      this.spoc_city_classification_list[0].children = this.sortNestedArrayAlphabetically(
        this.spoc_city_classification_list[0].children
      );
      console.log("getCityClassificationSpocs",this.spoc_city_classification_list);
      spocs_data.forEach(element => {
        this.spoc_city_classification_list[0].children.forEach(city => {
          if(element.spoc_city == city.label){
            if (!city.children.find(item => item.label === element.city_classification)) { //to remove city classification duplicates
              if(element.city_classification != null){
                var obj = {
                  id: element.spoc_city + "_" + element.city_classification, // combine city and ccf because x is in more than one city
                  label: element.city_classification,
                  children: [],
                };
                city.children.push(obj);
              }
            }
          }
        });   
      });
      spocs_data.forEach(element => {
        this.spoc_city_classification_list[0].children.forEach(city => {
          city.children.forEach(ccf => {
            if (!ccf.children.find(item => item.id === element.spoc_id + "_" + element.spoc_city + "_" + element.city_classification)) { //To remove spoc id duplicates
              if(element.spoc_city + "_" + element.city_classification == city.label + "_" + ccf.label){   //To remove spoc id duplicates
                var obj = {
                  id: element.spoc_id + "_" + ccf.id, //To remove spoc id duplicates
                  // id: element.spoc_id,
                  label: element.current_spoc,
                  city: element.spoc_city,
                  city_classification: element.city_classification,
                  spoc_id : element.spoc_id
                };
                ccf.children.push(obj);
              } 
            }
          });  
        });
      });
      // console.log("",spoc);
    },

    summariseBySources(data) {
      console.log("data----", data);
      if (this.SelectTeam === "CM") {
        let sourceStats = {
          all: {
            name: "All",
            total: 0,
            unduplicated: 0,
            untouched: 0,
            mhp: 0,
            new_enquiry: 0,
            re_enquiry: 0,
            P1: 0,
            P2: 0,
            P3: 0,
            enrolled: 0,
            invalid: 0,
          },
        };
        console.log("sourceStats----", sourceStats);
        if(this.selected_city.length == 0 && this.selected_spoc_city.length == 0){
          sourceStats.all.spoc_city = "";
          sourceStats.all.city = "";
          sourceStats.all.city_classification = "";
          sourceStats.all.spoc_id = "";
        }
        if(this.selected_city.length > 0){
          sourceStats.all.spoc_city = ""; //Made it empty to check condition in CmTeam
          sourceStats.all.city = this.selected_cities_list;
          sourceStats.all.city_classification = this.selected_city_classifications;
          sourceStats.all.spoc_id = this.selected_spoc_ids;
        }
        if(this.selected_spoc_city.length > 0){
          sourceStats.all.city = "";  //Made it empty to check condition in CmTeam
          sourceStats.all.spoc_city = this.selected_spoc_cities_list;
          sourceStats.all.city_classification = this.selected_city_classifications;
          sourceStats.all.spoc_id = this.selected_spoc_ids;
        }
        let last_ten_digits = [];
        this.sorted_data = _.sortBy(data, "engagement_added_on").reverse();
        console.log("sorted_data", this.sorted_data);
        // Check if source key exists
        // if not insert new, else update count of the source stat
        this.sorted_data.forEach((element) => {
          // console.log("sourceStats", element )
          element.source = this.getDisplaySource(element.source);

          if (element.source in sourceStats) {
            sourceStats[element.source].total++;
            sourceStats.all.total++;
          } else {
            sourceStats[element.source] = {
              city: element.city,
              name: element.source,
              total: 1,
              unduplicated: 0,
              untouched: 0,
              mhp: 0,
              new_enquiry: 0,
              re_enquiry: 0,
              P1: 0,
              P2: 0,
              P3: 0,
              enrolled: 0,
              invalid: 0,
            };
            if(this.selected_city.length == 0 && this.selected_spoc_city.length == 0){
              sourceStats[element.source].spoc_city = "";
              sourceStats[element.source].city = "";
              sourceStats[element.source].city_classification = "";
              sourceStats[element.source].spoc_id = "";
            }
            if(this.selected_city.length > 0){
              sourceStats[element.source].spoc_city = ""; //Made it empty to check condition in CmTeam
              sourceStats[element.source].city = this.selected_cities_list;
              sourceStats[element.source].city_classification = this.selected_city_classifications;
              sourceStats[element.source].spoc_id = this.selected_spoc_ids;
            }
            if(this.selected_spoc_city.length > 0){
              sourceStats[element.source].city = "";  //Made it empty to check condition in CmTeam
              sourceStats[element.source].spoc_city = this.selected_spoc_cities_list;
              sourceStats[element.source].city_classification = this.selected_city_classifications;
              sourceStats[element.source].spoc_id = this.selected_spoc_ids;
            }
            sourceStats.all.total++;
          }

          // if no person_id determine invalid or untouched
          // if (element.person_id === null) {
          //   if (element.invalid_net_enquiry === 1) {
          //     sourceStats[element.source].invalid++;
          //     sourceStats.all.invalid++;
          //   }
          //   // if (element.invalid_net_enquiry === 0 && element.mhp_status === 1) {
          //   //   sourceStats[element.source].untouched++;
          //   //   sourceStats.all.untouched++;
          //   // }
          //   if (element.invalid_net_enquiry === 0 && element.matched === 0) {
          //     sourceStats[element.source].untouched++;
          //     sourceStats.all.untouched++;
          //   }
          //   sourceStats[element.source].unduplicated++;
          //   sourceStats.all.unduplicated++;
          // } else {
            // Check if the person id is duplicated.
            // if yes just dont do anything. else update the corresponding level and increment unduplicated
            // console.log("element", person_ids.includes(element.person_id))
            if (!last_ten_digits.includes(element.last_ten_digits)) {
              // if (
              //   element.invalid_net_enquiry === 0 &&
              //   element.mhp_status === 1
              // ) {
              //   sourceStats[element.source].untouched++;
              //   sourceStats.all.untouched++;
              // }
              if (
                element.invalid_net_enquiry === 0 &&
                element.matched === 0
              ) {
                sourceStats[element.source].untouched++;
                sourceStats.all.untouched++;
              }
              if (element.invalid_net_enquiry === 1) {
                sourceStats[element.source].invalid++;
                sourceStats.all.invalid++;
              }
              if (element.enrolled === 1) {
                sourceStats[element.source].enrolled++;
                sourceStats.all.enrolled++;
              }
              sourceStats[element.source].unduplicated++;
              sourceStats.all.unduplicated++;

              let level = this.getCMDisplayLevel(element.level);
              sourceStats[element.source][level]++;
              sourceStats.all[level]++;
              if (element.mhp_status === 1) {
                sourceStats[element.source].mhp++;
                sourceStats.all.mhp++;
              }

              let lead_added_timestamp = moment(element.lead_added_date).unix();
              let selcted_date = this.convertToLocalTimestamp(this.dateRange.startDate);
              // console.log("timestamps", lead_added_timestamp, selcted_date);
              if(element.matched === 1){
                if (lead_added_timestamp >= selcted_date) {
                sourceStats[element.source].new_enquiry++;
                sourceStats.all.new_enquiry++;
                } else {
                sourceStats[element.source].re_enquiry++;
                sourceStats.all.re_enquiry++;
              }


              last_ten_digits.push(element.last_ten_digits);
            }
          }
        });
        console.log("sourceStats--", sourceStats);
        this.source_stats = [];
        this.source_stats.push(sourceStats.all);
        for (const source in sourceStats) {
          if (Object.hasOwnProperty.call(sourceStats, source)) {
            if (source !== "all") {
              this.source_stats.push(sourceStats[source]);
            }
          }
        }
      } else if (this.SelectTeam === "ExEd") {
        let sourceStats = {
          all: {
            name: "All",
            total: 0,
            unduplicated: 0,
            untouched: 0,
            mhp: 0,
            cold: 0,
            warm: 0,
            hot: 0,
            enrolled: 0,
            invalid: 0,
          },
        };
        let person_ids = [];

        this.sorted_data = _.sortBy(data, "engagement_added_on").reverse();
        // Check if source key exists
        // if not insert new, else update count of the source stat
        this.sorted_data.forEach((element) => {
          element.source = this.getDisplaySource(element.source);
          if (element.source in sourceStats) {
            sourceStats[element.source].total++;
            sourceStats.all.total++;
          } else {
            sourceStats[element.source] = {
              // city_classification: element.city_classification,
              // city: element.city,
              name: element.source,
              total: 1,
              unduplicated: 0,
              untouched: 0,
              mhp: 0,
              cold: 0,
              warm: 0,
              hot: 0,
              enrolled: 0,
              invalid: 0,
            };
            sourceStats.all.total++;
          }
          // if no person_id determine invalid or untouched
          if (element.person_id === null) {
            if (element.invalid_net_enquiry === 1) {
              sourceStats[element.source].invalid++;
              sourceStats.all.invalid++;
            }
            // if (element.invalid_net_enquiry === 0 && element.mhp_status === 1) {
            //   sourceStats[element.source].untouched++;
            //   sourceStats.all.untouched++;
            // }
            if (element.invalid_net_enquiry === 0 && element.matched === 0) {
              sourceStats[element.source].untouched++;
              sourceStats.all.untouched++;
            }
          } else {
            // Check if the person id is duplicated.
            // if yes just dont do anything. else update the corresponding level and increment unduplicated
            if (!person_ids.includes(element.person_id)) {
              // if (
              //   element.invalid_net_enquiry === 0 &&
              //   element.mhp_status === 1
              // ) {
              //   sourceStats[element.source].untouched++;
              //   sourceStats.all.untouched++;
              // }
              if (
                element.invalid_net_enquiry === 0 &&
                element.matched === 0
              ) {
                sourceStats[element.source].untouched++;
                sourceStats.all.untouched++;
              }
              if (element.invalid_net_enquiry === 1) {
                sourceStats[element.source].invalid++;
                sourceStats.all.invalid++;
              }
              sourceStats[element.source].unduplicated++;
              sourceStats.all.unduplicated++;
              let u_level = this.getExEdDisplayLevel(element.u_level);
              sourceStats[element.source][u_level]++;
              sourceStats.all[u_level]++;
              if (element.mhp_status === 1) {
                sourceStats[element.source].mhp++;
                sourceStats.all.mhp++;
              }
              if (element.enrolled === 1) {
                sourceStats[element.source].enrolled++;
                sourceStats.all.enrolled++;
              }
              person_ids.push(element.person_id);
            }
          }
        });

        this.source_stats = [];
        this.source_stats.push(sourceStats.all);
        for (const source in sourceStats) {
          if (Object.hasOwnProperty.call(sourceStats, source)) {
            if (source !== "all") {
              this.source_stats.push(sourceStats[source]);
            }
          }
        }
      }
      console.log("sources by city", this.source_stats);
    },

    getDisplaySource(raw_source) {
      if(raw_source != null){
        let position  = raw_source.length - 8;
        let check_word = "";
        check_word = raw_source.substring(position);
        if(check_word == '-webinar'){
          raw_source = "";
          raw_source = check_word;
        }
      }
      let source = this.source_mapping[raw_source];
      if (source === undefined || source === "") {
        source = "other";
      }
      return source;
    },

    getCMDisplayLevel(cm_level) {
      let cmLevel = this.cm_level_mapping[cm_level];
      if (cmLevel === undefined) {
        cmLevel = "cold";
      }
      return cmLevel;
    },

    getExEdDisplayLevel(raw_level) {
      let level = this.exed_level_mapping[raw_level];
      if (level === undefined) {
        level = "cold";
      }
      return level;
    },
    convertToLocalTimestamp(val){
        var d = new Date(val);
        d.setHours(0,0,0,0);
        return this.getDate(d.toLocaleString('en-US', { timeZone: 'Asia/Kolkata' }));
    },
    getDate(date) {
        var datum = Date.parse(date);
        return datum/1000;
    },

    displayDetails(selected_sources_string) {
      
      console.log("displaying details", selected_sources_string);
      // console.log(selected_sources_string.split(","));
      let selected_sources = selected_sources_string.split(",");
      this.selected_source = selected_sources;

      if (selected_sources_string == "All") {
        this.selected_source = selected_sources_string;
        selected_sources = this.sources;
      }
      this.populateSpocStats(selected_sources);
      this.populateCampaignStats(selected_sources);
    },

    populateSpocStats(selected_sources) {
      this.spoc_stats = [];
      if (this.SelectTeam === "CM") {
        let spocStats = {};
        let last_ten_digits = [];

        this.sorted_data.forEach((element) => {
          let display_source = this.getDisplaySource(element.source);

          if (selected_sources.includes(display_source)) {
            const spoc_key = "spoc-" + element.current_spoc;
            //    console.log("selected_sources", spoc_key)
            if (spoc_key in spocStats) {
              spocStats[spoc_key].total++;
            } else {
              spocStats[spoc_key] = {
                name: element.current_spoc,
                source: element.source,
                total: 0,
                unduplicated: 0,
                untouched: 0,
                mhp: 0,
                new_enquiry: 0,
                re_enquiry: 0,
                P1: 0,
                P2: 0,
                P3: 0,
                enrolled: 0,
                invalid: 0,
              };
              spocStats[spoc_key].total++;
              if(this.selected_city.length == 0 && this.selected_spoc_city.length == 0){
              spocStats[spoc_key].spoc_city = "";
              spocStats[spoc_key].city = "";
              spocStats[spoc_key].city_classification = element.city_classification;
              spocStats[spoc_key].spoc_id = "";
            }
            if(this.selected_city.length > 0){
              spocStats[spoc_key].spoc_city = ""; //Made it empty to check condition in CmTeam
              spocStats[spoc_key].city = this.selected_cities_list;
              spocStats[spoc_key].city_classification = element.city_classification;
              spocStats[spoc_key].spoc_id = this.selected_spoc_ids;
            }
            if(this.selected_spoc_city.length > 0){
              spocStats[spoc_key].city = "";  //Made it empty to check condition in CmTeam
              spocStats[spoc_key].spoc_city = this.selected_spoc_cities_list;
              spocStats[spoc_key].city_classification = element.city_classification;
              spocStats[spoc_key].spoc_id = this.selected_spoc_ids;
            }
            }
            // if (element.person_id === null) {
            //   if (element.invalid_net_enquiry === 1) {
            //     spocStats[spoc_key].invalid++;
            //   }
            //   // if (
            //   //   element.invalid_net_enquiry === 0 &&
            //   //   element.mhp_status === 1
            //   // ) {
            //   //   spocStats[spoc_key].untouched++;
            //   // }
            //   if (
            //     element.invalid_net_enquiry === 0 &&
            //     element.matched === 0
            //   ) {
            //     spocStats[spoc_key].untouched++;
            //   }
            // } else {
              if (!last_ten_digits.includes(element.last_ten_digits)) {
                spocStats[spoc_key].unduplicated++;
                // if (
                //   element.invalid_net_enquiry === 0 &&
                //   element.mhp_status === 1
                // ) {
                //   spocStats[spoc_key].untouched++;
                // }
                if (
                  element.invalid_net_enquiry === 0 &&
                  element.matched === 0
                ) {
                  spocStats[spoc_key].untouched++;
                }

                let level = this.getCMDisplayLevel(element.level);
                // console.log("selected_sources", element)
                spocStats[spoc_key][level]++;
                if (element.mhp_status === 1) {
                  spocStats[spoc_key].mhp++;
                }
                if (
                  element.invalid_net_enquiry === 1 &&
                  element.mhp_status === 0
                ) {
                  spocStats[spoc_key].invalid++;
                }
                if (element.enrolled === 1) {
                  spocStats[spoc_key].enrolled++;
                }

                let lead_added_timestamp = moment(element.lead_added_date).unix();
                let selcted_date = this.convertToLocalTimestamp(this.dateRange.startDate);
                if (lead_added_timestamp > selcted_date) {
                  spocStats[spoc_key].new_enquiry++;
                } else {
                  spocStats[spoc_key].re_enquiry++;
                }
                last_ten_digits.push(element.last_ten_digits);
              }
            // }
          }
        });
        for (const spocStat in spocStats) {
          if (Object.hasOwnProperty.call(spocStats, spocStat)) {
            this.spoc_stats.push(spocStats[spocStat]);

            this.spoc_stats = this.spoc_stats.reduce((unique, o) => {
              if (!unique.some((spocStats) => spocStats.name === o.name)) {
                unique.push(o);
              }
              return unique;
            }, []);
          }
        }
        console.log("spoc_stats", this.spoc_stats);
        // this.spoc_stats = _.sortBy(this.spoc_stats, "name");
        this.spoc_stats = _.sortBy(this.spoc_stats, "city_classification");
      } else if (this.SelectTeam === "ExEd") {
        let spocStats = {};
        let person_ids = [];
        this.spoc_stats = [];
        this.sorted_data.forEach((element) => {
          let display_source = this.getDisplaySource(element.source);
          if (selected_sources.includes(display_source)) {
            const spoc_key = "spoc-" + element.current_spoc;
            if (spoc_key in spocStats) {
              spocStats[spoc_key].total++;
            } else {
              spocStats[spoc_key] = {
                name: element.current_spoc,
                source: element.source,
                total: 0,
                unduplicated: 0,
                untouched: 0,
                mhp: 0,
                cold: 0,
                warm: 0,
                hot: 0,
                enrolled: 0,
                invalid: 0,
              };
              spocStats[spoc_key].total++;
            }
            if (element.person_id === null) {
              if (
                element.invalid_net_enquiry === 1 &&
                element.mhp_status === 0
              ) {
                spocStats[spoc_key].invalid++;
              }
              if (
                element.invalid_net_enquiry === 0 &&
                element.matched === 0
              ) {
                spocStats[spoc_key].untouched++;
              }
            } else {
              if (!person_ids.includes(element.person_id)) {
                // if (
                //   element.invalid_net_enquiry === 0 &&
                //   element.mhp_status === 1
                // ) {
                //   spocStats[spoc_key].untouched++;
                // }
                if (
                  element.invalid_net_enquiry === 0 &&
                  element.matched === 0
                ) {
                  spocStats[spoc_key].untouched++;
                }
                if (
                  element.invalid_net_enquiry === 1 &&
                  element.mhp_status === 0
                ) {
                  spocStats[spoc_key].invalid++;
                }
                spocStats[spoc_key].unduplicated++;
                let u_level = this.getExEdDisplayLevel(element.u_level);
                spocStats[spoc_key][u_level]++;
                if (element.mhp_status === 1) {
                  spocStats[spoc_key].mhp++;
                }
                if (element.enrolled === 1) {
                  spocStats[spoc_key].enrolled++;
                }
                person_ids.push(element.person_id);
              }
            }
          }
        });
        for (const spocStat in spocStats) {
          if (Object.hasOwnProperty.call(spocStats, spocStat)) {
            this.spoc_stats.push(spocStats[spocStat]);
            this.spoc_stats = this.spoc_stats.reduce((unique, o) => {
              if (!unique.some((spocStats) => spocStats.name === o.name)) {
                unique.push(o);
              }
              return unique;
            }, []);
          }
        }
        this.spoc_stats = _.sortBy(this.spoc_stats, "name");
        console.log("spoc_stats", this.spoc_stats);
      }
    },

    populateCampaignStats(selected_sources) {
      this.campaign_stats = [];
      if (this.SelectTeam === "CM") {
        let campaignStats = {};
        let last_ten_digits = [];
        this.campaign_stats = [];

        this.sorted_data.forEach((element) => {
          let display_source = this.getDisplaySource(element.source);
          if (selected_sources.includes(display_source)) {
            const campaign = element.campaign;
            if (campaign in campaignStats) {
              campaignStats[campaign].total++;
            } else {
              campaignStats[campaign] = {
                name: element.campaign,
                source:element.source,
                total: 0,
                unduplicated: 0,
                untouched: 0,
                mhp: 0,
                new_enquiry: 0,
                re_enquiry: 0,
                P1: 0,
                P2: 0,
                P3: 0,
                enrolled: 0,
                invalid: 0,
              };
              if(this.selected_city.length == 0 && this.selected_spoc_city.length == 0){
                campaignStats[campaign].spoc_city = "";
                campaignStats[campaign].city = "";
                campaignStats[campaign].city_classification = "";
                campaignStats[campaign].spoc_id = "";
              }
              if(this.selected_city.length > 0){
                campaignStats[campaign].spoc_city = ""; //Made it empty to check condition in CmTeam
                campaignStats[campaign].city = this.selected_cities_list;
                campaignStats[campaign].city_classification = this.selected_city_classifications;
                campaignStats[campaign].spoc_id = this.selected_spoc_ids;
              }
              if(this.selected_spoc_city.length > 0){
                campaignStats[campaign].city = "";  //Made it empty to check condition in CmTeam
                campaignStats[campaign].spoc_city = this.selected_spoc_cities_list;
                campaignStats[campaign].city_classification = this.selected_city_classifications;
                campaignStats[campaign].spoc_id = this.selected_spoc_ids;
              }
              campaignStats[campaign].total++;
              console.log("campaignStats campaigns", campaignStats[campaign]);
            }

            // if (element.person_id === null) {
            //   if (
            //     element.invalid_net_enquiry === 1 &&
            //     element.mhp_status === 0
            //   ) {
            //     campaignStats[campaign].invalid++;
            //   }
            //   // if (
            //   //   element.invalid_net_enquiry === 0 &&
            //   //   element.mhp_status === 1
            //   // ) {
            //   //   campaignStats[campaign].untouched++;
            //   // }
            //   if (
            //     element.invalid_net_enquiry === 0 &&
            //     element.matched === 0
            //   ) {
            //     campaignStats[campaign].untouched++;
            //   }
            // } else {
              if (!last_ten_digits.includes(element.last_ten_digits)) {
                // if (element.level === "" || element.level === "null") {
                //   campaignStats[campaign].untouched++;
                // }
                if (element.invalid_net_enquiry === 0 &&
                  element.matched === 0) {
                  campaignStats[campaign].untouched++;
                }
                if (
                  element.invalid_net_enquiry === 1 &&
                  element.mhp_status === 0
                ) {
                  campaignStats[campaign].invalid++;
                }
                campaignStats[campaign].unduplicated++;

                let level = this.getCMDisplayLevel(element.level);
                campaignStats[campaign][level]++;

                if (element.mhp_status === 1) {
                  campaignStats[campaign].mhp++;
                }
                let lead_added_timestamp = moment(element.lead_added_date).unix();
                let selcted_date = this.convertToLocalTimestamp(this.dateRange.startDate);
                if(element.matched === 1){
                  if (lead_added_timestamp >= selcted_date) {
                    campaignStats[campaign].new_enquiry++;
                  } else {
                    campaignStats[campaign].re_enquiry++;
                  }
                }
                if (element.enrolled === 1) {
                  campaignStats[campaign].enrolled++;
                }
                last_ten_digits.push(element.last_ten_digits);
              }
            // }
          }
        });
        for (const campaignStat in campaignStats) {
          if (Object.hasOwnProperty.call(campaignStats, campaignStat)) {
            // this.campaign_stats.push(campaignStats[campaignStat]);
            this.campaign_stats.push(campaignStats[campaignStat]);

            // this.campaign_stats = this.campaign_stats.reduce(
            //     (unique, o) => {
            //         if (
            //             !unique.some(
            //                 (campaignStats) => campaignStats.campaign === o.campaign
            //             )
            //         ) {
            //             unique.push(o);
            //         }
            //         return unique;
            //     },
            //     []
            // );
          }
        }
        this.campaign_stats = _.sortBy(this.campaign_stats, "name");
        console.log("campaign_stats", this.campaign_stats);
      } else if (this.SelectTeam === "ExEd") {
        let campaignStats = {};
        let person_ids = [];
        this.campaign_stats = [];

        this.sorted_data.forEach((element) => {
          let display_source = this.getDisplaySource(element.source);
          if (selected_sources.includes(display_source)) {
            const campaign = element.campaign;
            if (campaign in campaignStats) {
              campaignStats[campaign].total++;
            } else {
              campaignStats[campaign] = {
                name: element.campaign,
                source:element.source,
                total: 0,
                unduplicated: 0,
                untouched: 0,
                mhp: 0,
                cold: 0,
                warm: 0,
                hot: 0,
                enrolled: 0,
                invalid: 0,
              };
              campaignStats[campaign].total++;
            }

            if (element.person_id === null) {
              if (
                element.invalid_net_enquiry === 1 &&
                element.mhp_status === 0
              ) {
                campaignStats[campaign].invalid++;
              } else if (element.invalid_net_enquiry === 0 &&
                element.matched === 0) {
                campaignStats[campaign].untouched++;
              }
            } else {
              if (!person_ids.includes(element.person_id)) {
                // if (
                //   element.iiml_level === "" ||
                //   element.iiml_level === "null"
                // ) {
                //   campaignStats[campaign].untouched++;
                // }
                if (
                  element.invalid_net_enquiry === 0 &&
                  element.matched === 0
                ) {
                  campaignStats[campaign].untouched++;
                }
                if (
                  element.invalid_net_enquiry === 1 &&
                  element.mhp_status === 0
                ) {
                  campaignStats[campaign].invalid++;
                }
                campaignStats[campaign].unduplicated++;

                let u_level = this.getExEdDisplayLevel(element.u_level);
                campaignStats[campaign][u_level]++;

                if (element.mhp_status === 1) {
                  campaignStats[campaign].mhp++;
                }
                if (element.enrolled === 1) {
                  campaignStats[campaign].enrolled++;
                }
                person_ids.push(element.person_id);
              }
            }
          }
        });

        for (const campaignStat in campaignStats) {
          if (Object.hasOwnProperty.call(campaignStats, campaignStat)) {
            this.campaign_stats.push(campaignStats[campaignStat]);
            // this.campaign_stats = this.campaign_stats.reduce(
            //     (unique, o) => {
            //         if (
            //             !unique.some(
            //                 (campaignStats) => campaignStats.campaign === o.campaign
            //             )
            //         ) {
            //             unique.push(o);
            //         }
            //         return unique;
            //     },
            //     []
            // );
          }
        }
        this.campaign_stats = _.sortBy(this.campaign_stats, "name");
        // console.log("campaign_stats", this.campaign_stats);
      }
    },

    isMHPWithInSelectedDates(mhp_date) {
      if (mhp_date < this.datatoTimestamp(this.dateRange.endDate) && mhp_date > this.datatoTimestamp(this.dateRange.startDate)) {
        return true;
      }
      else {
        return false;
      }
    }
  },
  watch: {
    selected_spoc_city(val){
      console.log("selected_spoc_city ids and ccf", val);
      if(val.length != 0){
        this.spoc_stats = [];
        this.campaign_stats = [];
        this.selected_city = [];
        console.log("getCcfData",val);
        let ne_data = []
        this.selected_spoc_ids = [];
        this.selected_city_classifications = [];
        for (let i = 0; i < this.display_data.length; i++) {
          const data_element = this.display_data[i];
          for (let j = 0; j < val.length; j++) {
            const city = val[j];
            if(data_element.spoc_id + "_" + data_element.spoc_city + "_" + data_element.city_classification == city.id){
              ne_data.push(data_element);
              console.log("selected cities", data_element.city_classification, data_element.city);
              if (!this.selected_spoc_ids.includes(city.spoc_id)){
                this.selected_spoc_ids.push(city.spoc_id);
              }
              if (!this.selected_city_classifications.includes(city.city_classification)){
                this.selected_city_classifications.push(city.city_classification);
              }
              if(this.selected_spoc_city.length > 0){
                if(!this.selected_spoc_cities_list.includes(city.city)){
                  this.selected_spoc_cities_list.push(city.city);
                }
              }
              if(this.selected_city.length > 0){
                if(!this.selected_cities_list.includes(city.city)){
                  this.selected_cities_list.push(city.city);
                }
              }
            }
          }
        }
        this.summariseBySources(ne_data);
        
      }
    },
    selected_city(val){
      console.log("selected_city ids and ccf", val);
      if(val.length != 0){
        this.spoc_stats = [];
        this.campaign_stats = [];
        this.selected_spoc_city = [];
         console.log("getCcfData",val);
        let ne_data = []
        this.selected_spoc_ids = [];
        this.selected_city_classifications = [];
        for (let i = 0; i < this.display_data.length; i++) {
          const data_element = this.display_data[i];
          for (let j = 0; j < val.length; j++) {
            const city = val[j];
            if(data_element.spoc_id + "_" + data_element.city + "_" + data_element.city_classification == city.id){
              ne_data.push(data_element);
              console.log("selected cities", data_element.city_classification, data_element.city);
              if (!this.selected_spoc_ids.includes(city.spoc_id)){
                this.selected_spoc_ids.push(city.spoc_id);
              }
              if (!this.selected_city_classifications.includes(city.city_classification)){
                this.selected_city_classifications.push(city.city_classification);
              }
              if(this.selected_spoc_city.length > 0){
                if(!this.selected_spoc_cities_list.includes(city.city)){
                  this.selected_spoc_cities_list.push(city.city);
                }
              }
              if(this.selected_city.length > 0){
                if(!this.selected_cities_list.includes(city.city)){
                  this.selected_cities_list.push(city.city);
                }
              }
            }
          }
        }
        this.summariseBySources(ne_data);
      }
    },
    selected_course: function (value) {
      console.log(value);
      if (value === "IITM-AA(IIT-AI)") {
        this.courseArray = [];
        let obj = [
          "IITM-AA",
          "IIT-AI",
          "IIT-Roorkee-AI",
          "AI-ML",
          "Applied AI & ML (IIT Mandi)",
          "AI",
          "IIT-Mandi-Applied-AI",
          "IIT=AI",
          "IIT Mandi - Applied AI & ML",
          "IITR-DB",
          "IITR-DBE",
          "AI-DBE",
          "IIT Roorkee - AI for Digital Businesses and E-commerce",
        ];
        this.courseArray.push(obj);
      } else if (value === "IIML-BA") {
        this.courseArray = [];
        let obj = [
          "IIML-BA",
          "NO-BA",
          "EA",
          "Business Analytics (IIM Lucknow)",
          "IITR-BA",
          "IML-BA",
        ];
        this.courseArray.push(obj);
      } else if (value === "IIML-PM") {
        this.courseArray = [];
        let obj = ["IIML-PM"];
        this.courseArray.push(obj);
      } else if (value === "IIMK-FT") {
        this.courseArray = [];
        let obj = ["IIMK-FT"];
        this.courseArray.push(obj);
      } else if (value === "IIML-AB") {
        this.courseArray = [];
        let obj = ["IIML-AB"];
        this.courseArray.push(obj);
      } else if (value === "IIML-SH") {
        this.courseArray = [];
        let obj = ["IIML-SH"];
        this.courseArray.push(obj);
      } else if (value === "IITJ-DE") {
        this.courseArray = [];
        let obj = ["IITJ-DE"];
        this.courseArray.push(obj);
      } else if (value === "XLRI-HR") {
        this.courseArray = [];
        let obj = ["XLRI-HR"];
        this.courseArray.push(obj);
      }else if (value === "XLRI-SH") {
        this.courseArray = [];
        let obj = ["XLRI-SH"];
        this.courseArray.push(obj);
      }  else if (value === "IIMI-BA") {
        this.courseArray = [];
        let obj = ["IIMI-BA"];
        this.courseArray.push(obj);
      } else if (value === "IIMI-AA") {
        this.courseArray = [];
        let obj = ["IIMI-AA"];
        this.courseArray.push(obj);
      }else if (value === "XLRI-DM") {
        this.courseArray = [];
        let obj = ["XLRI-DM"];
        this.courseArray.push(obj);
      } else if (value === "IIML-HR") {
        this.courseArray = [];
        let obj = [
          "IIML-HR",
          "HR Analytics (IIM Lucknow)",
          "IIML-HA",
          "IIML-HRM",
        ];
        this.courseArray.push(obj);
      } else if (value === "IITR-BF") {
        this.courseArray = [];
        let obj = ["IITR-BF", "IIT Roorkee - AI for BFSI"];
        this.courseArray.push(obj);
      } else if (value === "IITR-DB(IIT-AI)") {
        this.courseArray = [];
        let obj = [
          "IITM-AA",
          "IIT-AI",
          "IIT-Roorkee-AI",
          "AI-ML",
          "Applied AI & ML (IIT Mandi)",
          "AI",
          "IIT-Mandi-Applied-AI",
          "IIT=AI",
          "IIT Mandi - Applied AI & ML",
          "IITR-DB",
          "IITR-DBE",
          "AI-DBE",
          "IIT Roorkee - AI for Digital Businesses and E-commerce",
        ];
        this.courseArray.push(obj);
      } else if (value === "IIT-AI(Only)") {
        this.courseArray = [];
        let obj = ["IIT-AI"];
        this.courseArray.push(obj);
      } else if (value === "IITM-AA(Only)") {
        this.courseArray = [];
        let obj = ["IITM-AA"];
        this.courseArray.push(obj);
      } else if (value === "IIMK-CX") {
        this.courseArray = [];
        let obj = ["IIMK-CX"];
        this.courseArray.push(obj);
      } else if (value === "IITM-FS") {
        this.courseArray = [];
        let obj = ["IITM-FS"];
        this.courseArray.push(obj);
      } else if (value === "IITR-CC") {
        this.courseArray = [];
        let obj = ["IITR-CC"];
        this.courseArray.push(obj);
      } else if (value === "IIMK-FT") {
        this.courseArray = [];
        let obj = ["IIMK-FT"];
        this.courseArray.push(obj);
      } else if (value === "IIML-AB") {
        this.courseArray = [];
        let obj = ["IIML-AB"];
        this.courseArray.push(obj);
      } else if (value === "IIML-SH") {
        this.courseArray = [];
        let obj = ["IIML-SH"];
        this.courseArray.push(obj);
      } else if (value === "IITJ-DE") {
        this.courseArray = [];
        let obj = ["IITJ-DE"];
        this.courseArray.push(obj);
      } else if (value === "XLRI-HR") {
        this.courseArray = [];
        let obj = ["XLRI-HR"];
        this.courseArray.push(obj);
      } else if (value === "XLRI-SH") {
        this.courseArray = [];
        let obj = ["XLRI-SH"];
        this.courseArray.push(obj);
      } else if (value === "IIMI-BA") {
        this.courseArray = [];
        let obj = ["IIMI-BA"];
        this.courseArray.push(obj);
      } else if (value === "XLRI-DM") {
        this.courseArray = [];
        let obj = ["XLRI-DM"];
        this.courseArray.push(obj);
      } else if (value === "CMA") {
        this.courseArray = [];
        let obj = [
          "CMA",
          "HYD-CMA-005976",
          "CMA",
          "US CMA",
          "Regarding cma",
          "I have done CMA( ICWAI)..",
          "I am currently at 11th standard ",
          "Icma sir IAM intresting",
          "IAM new joing this icma",
        ];
        this.courseArray.push(obj);
      } else if (value === "CPA") {
        this.courseArray = [];
        let obj = [
          "CPA",
          "FOF(O)",
          "RPA",
          "DA",
          "FOF",
          "IIML",
          "WCBA",
          "HYD-CPA-001843",
          "FOFO",
          "Undecided / Any of the above",
          "CPA2",
          "Aicpa",
          "Coa",
          "CPA USA",
          "i am interested in course 2 and  3",
          "US CPA",
          "Can US CPA be done in India?",
          "May I know the fee for CPA",
          "Both",
        ];
        this.courseArray.push(obj);
      } else if (value === "CPA/CMA") {
        this.courseArray = [];
        let obj = [
          "CPA",
          "FOF(O)",
          "RPA",
          "DA",
          "FOF",
          "IIML",
          "WCBA",
          "HYD-CPA-001843",
          "FOFO",
          "Undecided / Any of the above",
          "CPA2",
          "Aicpa",
          "Coa",
          "CPA USA",
          "i am interested in course 2 and  3",
          "US CPA",
          "Can US CPA be done in India?",
          "May I know the fee for CPA",
          "Both",
          "CMA",
          "HYD-CMA-005976",
          "CMA",
          "US CMA",
          "Regarding cma",
          "I have done CMA( ICWAI)..",
          "I am currently at 11th standard ",
          "Icma sir IAM intresting",
          "IAM new joing this icma",
        ];
        this.courseArray.push(obj);
      } else if (value === "IIML-SF") {
        this.courseArray = [];
        let obj = [
          "IIML-SF",
          "Strategic-Finance",
          "IIT-SF",
          "IIM Lucknow Executive Program in Strategic Finance",
        ];
        this.courseArray.push(obj);
        this.SelectTeam = "CM";
      } else if (value === "IIML-FA") {
        this.courseArray = [];
        let obj = [
          "IIML-FA",
          "IIM-L",
          " IIML-FA",
          "Analytics for Finance (IIM Lucknow)",
          "IIML FA",
          " Analytics for Finance",
        ];
        this.courseArray.push(obj);
        this.SelectTeam = "CM";
      } else if (value === "CFA") {
        this.courseArray = [];
        let obj = ["CFA"];
        this.courseArray.push(obj);
        this.SelectTeam = "CM";
      } else if (value === "FRM") {
        this.courseArray = [];
        let obj = ["FRM"];
        this.courseArray.push(obj);
        this.SelectTeam = "CM";
      } else if (value === "CFA/FRM") {
        this.courseArray = [];
        let obj = ["CFA", "FRM"];
        this.courseArray.push(obj);
        this.SelectTeam = "CM";
      } else if (value === "USP") {
        this.courseArray = [];
        let obj = ["USP", "MACC", "MS-STEM"];
        this.courseArray.push(obj);
        this.SelectTeam = "CM";
      } else if (value === "IIML-FT") {
        this.courseArray = [];
        let obj = ["IIML-FT"];
        this.courseArray.push(obj);
        this.SelectTeam = "CM";
      } else if (value === "IIML-SF") {
        this.courseArray = [];
        let obj = ["IIML-SF"];
        this.courseArray.push(obj);
        this.SelectTeam = "CM";
      }
    },
    cm_selected_course: function (value) {
      console.log(value);
    },
  },
  mounted() {
    this.courses = this.cm_courses;
    this.SelectTeam = "CM";
    this.myprofile();
    this.startdateepoch = this.datatoTimestamp(this.dateRange.startDate);
    this.enddateepoch = this.datatoTimestamp(this.dateRange.endDate);
    // console.log("end date epoc", this.enddateepoch);
  },
  components: {
    SourceStats: SourceStats,
    SpocStats: SpocStats,
    CampaignStats: CampaignStats,
    vSelect,
    // "v-select": vSelect,
    Treeselect,
    VSelectTree,
    VTree,
    DateRangePicker,
    moment,
    CmTeam: CmTeam,
    CmSpoc: CmSpoc,
    CmCampain: CmCampain,
    VueJsonToCsv,
    netEnquiryExportcsv: netEnquiryExportcsv,
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/vuesax/pages/dropdown.scss";
.headr {
  color: gray;
  font-size: 25px;
  margin-bottom: 2%;
}
.gap {
  margin-bottom: 4%;
}
.inicolor {
  color: gray;
}
.cardtext {
  color: gray;
  font-size: 20px;
}
.tree3 {
  padding: 10px;
  box-sizing: border-box;
  border: 1px dotted #ccccdd;
  overflow: auto;
  height: 500px;
  width: 300px; 
}
.vs-dropdown--menu {
  width: 300px;
}
.a-icon {
  outline: none;
  text-decoration: none !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
.gap {
  margin-right: 1%;
}
.examplex {
  display: flex;
  align-items: center;
  justify-content: center;
}
.a-icon {
  outline: none;
  text-decoration: none !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
i {
  font-size: 18px;
}
.vl {
  border-left: 1px solid black;
  height: 35px;
}
#style-1::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #f5f5f5;
}
.scrollbar {
  overflow-y: scroll;
}
</style>
