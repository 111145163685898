<template>
    <div style="margin-top: 3%">
        <vs-table :data="source_stats">
            <template slot="header">
                <vs-row style="margin-bottom: 1%">
                    <vs-col vs-w="9">
                        <h3>Source Stats</h3>
                    </vs-col>
                    <!-- <vs-col vs-w="3" style="text-align: right">
                        <vue-json-to-csv
                            :json-data="source_stats"
                            :csv-title="'netenquiry source report'"
                        >
                            <vs-button size="small" color="dark"
                                >Export Source CSV</vs-button
                            >
                        </vue-json-to-csv>
                    </vs-col> -->
                </vs-row>
            </template>
            <template slot="thead">
                <vs-th></vs-th>
                <vs-th> Source </vs-th>
                <vs-th> Total </vs-th>
                <vs-th> Unique </vs-th>
                <vs-th> Yet To Connect </vs-th>
                <vs-th> Cold </vs-th>
                <vs-th> Warm </vs-th>
                <vs-th> Hot </vs-th>
                <vs-th> Enrolled </vs-th>
                <vs-th> Invalid </vs-th>
                <vs-th> MHP </vs-th>
                <vs-th> New Enquiry </vs-th>
                <vs-th> Re-Enquiry </vs-th>
                <vs-th v-if="userid === 132 || userid === 122 || userid === 39"> Action </vs-th>
            </template>

            <template slot-scope="{ data }">
                <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                    <vs-td >
                        <vs-checkbox v-model="selected" :vs-value="tr" ></vs-checkbox>   
                    </vs-td>
                    <vs-td :data="data[indextr].name">
                        {{ data[indextr].name }}
                    </vs-td>

                    <vs-td :data="data[indextr].total">
                        <a><p @click="passDataToPopup(tr, 'Total')"> {{ data[indextr].total }} </p></a>
                    </vs-td>

                    <vs-td :data="data[indextr].unduplicated">
                        <a><p @click="passDataToPopup(tr, 'Unduplicated')"> {{ data[indextr].unduplicated }} </p></a>
                    </vs-td>

                    <vs-td :data="data[indextr].untouched">
                        <a><p @click="passDataToPopup(tr, 'Yet To Connect')"> {{ data[indextr].untouched }} </p></a>
                    </vs-td>

                    <vs-td :data="data[indextr].cold">
                        <a><p @click="passDataToPopup(tr, 'Cold')"> {{ data[indextr].cold }} </p></a>
                    </vs-td>

                    <vs-td :data="data[indextr].warm">
                        <a><p @click="passDataToPopup(tr, 'Warm')"> {{ data[indextr].warm }} </p></a>
                    </vs-td>

                    <vs-td :data="data[indextr].hot">
                        <a><p @click="passDataToPopup(tr, 'Hot')"> {{ data[indextr].hot }} </p></a>
                    </vs-td>

                    <vs-td :data="data[indextr].enrolled">
                        <a><p @click="passDataToPopup(tr, 'Enrolled')"> {{ data[indextr].enrolled }} </p></a>
                    </vs-td>

                    <vs-td :data="data[indextr].invalid">
                        <a><p @click="passDataToPopup(tr, 'Invalid')"> {{ data[indextr].invalid }} </p></a>
                    </vs-td>

                    <vs-td :data="data[indextr].mhp">
                        <a><p @click="passDataToPopup(tr, 'MHP')"> {{ data[indextr].mhp }} </p></a>
                    </vs-td>

                     <vs-td :data="data[indextr].new_enquiry">
                        <a><p @click="passDataToPopup(tr, 'New Enquiry')"> {{ data[indextr].new_enquiry }} </p></a>
                    </vs-td>
                     <vs-td :data="data[indextr].re_enquiry">
                        <a><p @click="passDataToPopup(tr, 'Re Enquiry')"> {{ data[indextr].re_enquiry }} </p></a>
                    </vs-td>

                     <vs-td :data="data[indextr].mhp" v-if="userid === 132 || userid === 122 || userid === 39 ">
                        
                        <vue-json-to-csv
                            :json-data="raw_object_data"
                            :csv-title="
                                'netenquiryreport' + '-' + startdatecsv + '-' + enddatecsv + '-' + rawdataselectedsource
                            "
                        >
                            <vs-button type="filled" color="dark" @click="getSourceDataList(tr)"
                                >Export CSV</vs-button
                            >
                        </vue-json-to-csv>
                                
                    </vs-td>
                </vs-tr>
            </template>
        </vs-table>
        <pre>{{ selected_sources }}</pre>
        <vs-popup class="spoc_popup_width_source_stats"  style="z-index: 53000" :title="spoc_popup_title"
            :active.sync="spoc_data_popup">
            <p><b>Spoc Count : {{popup_spoc_data.length}}</b></p>
            <div>
                <vs-table max-items="10"  pagination  :data="this.popup_spoc_data">
                    <template slot="header">
                        <vs-row style="margin-bottom: 1%">
                        </vs-row>
                    </template>
                    <template slot="thead">
                        <vs-th> identity </vs-th>
                        <vs-th> Name </vs-th>
                        <vs-th> Current Spoc </vs-th>
                        <vs-th> Source </vs-th>
                        <vs-th> Course </vs-th>
                        <vs-th> M Level </vs-th>
                        <vs-th> U Level </vs-th>
                        <vs-th> Enquiry Date </vs-th>
                        <vs-th> View Info </vs-th>
                    </template>

                    <template slot-scope="{ data }">
                        <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                            <vs-td :data="data[indextr].identity">
                                {{ data[indextr].identity }}
                            </vs-td>
                            <vs-td :data="data[indextr].person_name">
                                {{ data[indextr].person_name }}
                            </vs-td>

                            <vs-td :data="data[indextr].current_spoc">
                                {{ data[indextr].current_spoc }}
                            </vs-td>

                            <vs-td :data="data[indextr].source">
                                {{ data[indextr].source }}
                            </vs-td>

                            <vs-td :data="data[indextr].course">
                                {{ data[indextr].course }}
                            </vs-td>

                            <vs-td :data="data[indextr].level">
                                {{ data[indextr].level }}
                            </vs-td>
                            <vs-td :data="data[indextr].u_level">
                                {{ data[indextr].u_level }}
                            </vs-td>
                            <vs-td :data="data[indextr].enquiry_date">
                                {{ data[indextr].enquiry_date }}
                            </vs-td>
                            <vs-td v-if="data[indextr].mwb_id != null && data.mwb_id != ''">
                                <div @click="openPopupEmit(tr)">
                                    <vs-icon icon="info_outline" size="small" color="dark"></vs-icon>
                                </div>
                            </vs-td>
                            <vs-td v-else>
                            </vs-td>
                        </vs-tr>
                    </template>
                </vs-table>
            </div>
        </vs-popup>
        <vs-popup class="holamundo" id="yet_to_connect_width" :title="spoc_popup_title"
            :active.sync="yet_to_connect_popup">
            <p><b>Spoc Count : {{popup_spoc_data.length}}</b></p>
            <div>
                <vs-table max-items="10" pagination :data="this.popup_spoc_data">
                    <template slot="header">
                        <vs-row style="margin-bottom: 1%">
                        </vs-row>
                    </template>
                    <template slot="thead">
                        <vs-th> Name </vs-th>
                        <vs-th> Course </vs-th>
                        <vs-th> Source </vs-th>
                        <vs-th> Campaign </vs-th>
                        <vs-th> Enquiry Date </vs-th>
                    </template>

                    <template slot-scope="{ data }">
                        <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                            <vs-td :data="data[indextr].name">
                                {{ data[indextr].name }}
                            </vs-td>
                            <vs-td :data="data[indextr].course">
                                {{ data[indextr].course }}
                            </vs-td>
                            <vs-td :data="data[indextr].source">
                                {{ data[indextr].source }}
                            </vs-td>
                            <vs-td :data="data[indextr].campaign">
                                {{ data[indextr].campaign }}
                            </vs-td>
                            <vs-td :data="data[indextr].enquiry_date">
                                {{ data[indextr].enquiry_date }}
                            </vs-td>
                        </vs-tr>
                    </template>
                </vs-table>
            </div>
        </vs-popup>
    </div>
</template>

<script>
import moment from "moment";
import VueJsonToCsv from "vue-json-to-csv";
export default {
    data: () => ({
        spoc_popup_title: "",
        popup_spoc_data: [],
        selected: [],
        yet_to_connect_popup: false,
        spoc_data_popup: false,
        selected_sources: "",
        raw_object_data: [],
        source_popup: false,
        rawdataselectedsource: "",
        // exed_level_mapping: {
        //     "Exam-Pass-Dropout": "Cold",
        //     // "Application Received": "hot",
        //     "Exam-Noshow-Dropout": "Cold",
        //     "Exam-Fail-Dropout": "Cold",
        //     "Exam-Fail": "Cold",
        //     DND: "Cold",
        //     // "Exam-Pass": "hot",
        //     // "Very Hot": "hot",
        //     // Hot: "hot",
        //     "N/A": "Cold",
        //     NULL: "Cold",
        //     Warm: "warm",
        //     Cold: "Cold",
        //     "CM-Yet-To-Connect": "Cold",
        // },
        source_mapping: {
                "Chak De CPA": "MAC Webinar",
                "CMA Alumni Speak": "MAC Webinar",
                "CMA Gully Boy": "MAC Webinar",
                "CPA Alumni Speak": "MAC Webinar",
                Discovery: "Discovery",
                Email: "Email",
                Facebook: "Facebook",
                google: "Google",
                "Incoming Call": "Incoming Call",
                "Incoming Call -SM": "Incoming Call",
                "Incoming Calls": "Incoming Call",
                "Incoming Calls -SM": "Incoming Call",
                IncomingCall: "Incoming Call",
                "Info Id -SM": "Info Id -SM",
                Instagram: "Instagram",
                IR: "IR Team",
                "JAVA-FS": "JAVA-FS",
                LeadSquared: "LeadSquared",
                Linkedin: "Linkedin",
                LiveChat: "Live Chat",
                "Live Chat": "Live Chat",
                Naukari: "Naukri Learning",
                Naukri: "Naukri Learning",
                "Naukri Learning": "Naukri Learning",
                None: "other",
                Organic: "Organic",
                "Organic -SM": "Organic -SM",
                Quora: "Quora",
                "Quora -SM": "Quora",
                SMS: "SMS",
                Test: "other",
                Testing: "other",
                undefined: "other",
                Verloop: "Chat",
                Chat: "Chat",
                "Voice SMS": "Voice SMS",
                Webinar: "Webinar - Registered",
                "Webinar - Registered": "Webinar - Registered",
                Website: "Website Form",
                "Website Chat": "Chat",
                "Website Form": "Website Form",
                "Website Search": "Website Form",
                "Website Whatsapp": "Website Whatsapp",
                "Wesbite Whatsapp": "Website Whatsapp",
                WhatsApp: "Website Whatsapp",
                YouTube: "YouTube",
                Google: "Google",
                Youtube: "YouTube",
                LinkedIn: "Linkedin",
                "Business Partner": "other",
                "Naukri-MR": "Naukri-MR",
                SquadIQ: "other",
                "Webinar-WAI": "Webinar - Registered",
                Wiley: "Wiley",
            },
            cm_level_mapping: {
                M6: "P1",
                M5: "P1",
                M4: "P3",
                "M4-": "P3",
                "M3++": "P1",
                "M3+": "P1",
                M3: "P1",
                M2: "P3",
                M1: "P3",
                "M2 - Did not Visit & Postponed": "P3",
                L6: "P2",
                L5: "P2",
                L4: "P2",
                "L4-": "P3",
                "L3+": "P2",
                L3: "P2",
                L2: "P2",
                L1: "P3",
                "L2-": "P2",
            },
    }),
    props: ["source_stats", "raw_data", "enddatecsv", "startdatecsv","userid"],
    watch: {
        selected: function () {
            this.rawdataselectedsource = "";
            this.selected_sources = "";
            let sources = [];
            for (let i = 0; i < this.selected.length; i++) {
                const source = this.selected[i];
                if (source.name === "All") {
                    this.rawdataselectedsource = "All";
                    this.selected_sources = "All";
                    this.selected = this.source_stats;
                    break;
                } else {
                    this.rawdataselectedsource = source.name;
                    sources.push(source.name);
                }
            }
            if (this.selected_sources !== "All") {
                this.selected_sources = sources.join();
            }
            this.$emit("source_changed", this.selected_sources);
        },
    },
    methods: {
        openPopupEmit(payload) {
            this.openBigPopup(payload.mwb_id);
        },
        passDataToPopup(popup_spoc_data_row, selected_level) {
            console.log("exed source stats and raw data", popup_spoc_data_row, selected_level, this.raw_data);
            // console.log("dcf",this.raw_data);
            
            this.spoc_popup_title = popup_spoc_data_row.name + ' ' + selected_level;
            let spoc_distinct = [];
            let person_ids = [];
            this.popup_spoc_data = [];
            let source_data = this.raw_data;
            for (let i = 0; i < source_data.length; i++) {
                let element = this.raw_data[i];
                // console.log("popup1",element.mhp_status, element.source, popup_spoc_data_row.name);
                let display_source = this.getDisplaySource(element.source);
                let selected_popup_source = this.getDisplaySource(popup_spoc_data_row.name);
                if (popup_spoc_data_row.name !== "All") {
                    if (selected_level === "Unduplicated") {
                        if (display_source === selected_popup_source && !person_ids.includes(element.person_id)) {
                            // if(element.person_id !== null){
                            spoc_distinct.push(element);
                            this.spoc_data_popup = true;
                            // }
                        }
                        person_ids.push(element.person_id);
                    }
                    if (selected_level === "New Enquiry") {
                        let lead_added_timestamp = moment(element.lead_added_date).unix();
                        if (display_source === selected_popup_source && element.person_id !== null && element.matched === 1 && lead_added_timestamp > element.enquiry_date_unix) {
                            spoc_distinct.push(element);
                            this.spoc_data_popup = true;
                        }
                    }
                    if (selected_level === "Re Enquiry") {
                        let lead_added_timestamp = moment(element.lead_added_date).unix();
                        if (display_source === selected_popup_source && element.person_id !== null && element.matched === 1 && lead_added_timestamp <= element.enquiry_date_unix) {
                            spoc_distinct.push(element);
                            this.spoc_data_popup = true;
                        }
                    }
                    if (selected_level === "Total") {
                        // console.log("display_source",display_source)
                        // console.log("selected_popup_source",selected_popup_source)
                        if (display_source === selected_popup_source) {
                            spoc_distinct.push(element);
                            // this.spoc_data_popup = true;
                        }
                    }
                    if (selected_level === "Warm" || selected_level === "Hot") {
                        if (display_source === selected_popup_source && selected_level === element.exed_level) {
                            spoc_distinct.push(element);
                            // this.spoc_data_popup = true;
                        }
                    }
                    if (selected_level === "Cold") {
                        if(display_source == selected_popup_source && (element.exed_level == "Cold" || element.exed_level == "cold" || element.exed_level == undefined || element.exed_level == "Busy/NL")){
                            console.log("cold not all",display_source, selected_popup_source);
                            // console.log("element",element);
                            spoc_distinct.push(element);
                       }
                       
                    }
                    if (selected_level === "Enrolled") {
                        if (element.enrolled === 1 && display_source === selected_popup_source) {
                            spoc_distinct.push(element);
                            // this.spoc_data_popup = true;
                        }
                    }
                    if (selected_level === "Invalid") {
                        if (element.invalid_net_enquiry === 1 && display_source === selected_popup_source) {
                            // console.log("Invalid", element.source);
                            spoc_distinct.push(element);
                            // this.spoc_data_popup = true;
                        }
                    }
                    if (selected_level === "MHP") {
                        if (element.mhp_status === 1 && display_source === selected_popup_source) {
                            // console.log("mhp check", element.source);
                            spoc_distinct.push(element);
                        }
                    }
                    if (selected_level === "Yet To Connect") {
                        if (element.invalid_net_enquiry === 0 && element.matched === 0 && display_source === selected_popup_source) {
                            spoc_distinct.push(element);
                            this.yet_to_connect_popup = true;
                        }
                    }
                }
                if (popup_spoc_data_row.name === "All") {
                    if (selected_level === "Unduplicated") {
                        if (!person_ids.includes(element.person_id)) {
                            if (element.person_id !== null) {
                                spoc_distinct.push(element);
                            }
                        }
                        person_ids.push(element.person_id);
                    }
                    if (selected_level === "New Enquiry") {
                        let lead_added_timestamp = moment(element.lead_added_date).unix();
                        if (element.person_id !== null && element.matched === 1 && lead_added_timestamp > element.enquiry_date_unix) {
                            spoc_distinct.push(element);
                        }
                    }
                    if (selected_level === "Re Enquiry") {
                        let lead_added_timestamp = moment(element.lead_added_date).unix();
                        if (element.person_id !== null && element.matched === 1 && lead_added_timestamp <= element.enquiry_date_unix) {
                            spoc_distinct.push(element);
                        }
                    }
                    if (selected_level === "Total") {
                        spoc_distinct.push(element);
                    }

                    // console.log("cold level",level);
                    if (selected_level === "Warm" || selected_level === "Hot") {
                        if (selected_level === element.exed_level) {
                            spoc_distinct.push(element);
                            // this.spoc_data_popup = true;
                        }
                    }
                    if (selected_level == "Cold") {
                        if(element.exed_level == "Cold" || element.exed_level == "cold" || element.exed_level == undefined || element.exed_level == "Busy/NL"){
                            spoc_distinct.push(element);
                        }
                    }
                    if (selected_level === "Enrolled") {
                        if (element.enrolled === 1) {
                            spoc_distinct.push(element);
                        }
                    }
                    if (selected_level === "MHP") {
                        if (element.mhp_status === 1) {
                            spoc_distinct.push(element);
                        }
                    }
                    if (selected_level === "Yet To Connect") {
                        if (element.invalid_net_enquiry === 0 && element.matched === 0) {
                            spoc_distinct.push(element);
                        }
                    }
                    if (selected_level === "Invalid") {
                        if (element.invalid_net_enquiry === 1) {
                            // console.log("Invalid", element.source);
                            spoc_distinct.push(element);
                        }
                    }
                }
            }
            let unique = [];
            if (selected_level !== "Yet To Connect" && selected_level !== "Total" && selected_level !== "Invalid") {
                for (let i = 0; i < spoc_distinct.length; i++) {
                    if (!unique[spoc_distinct[i].identity]) {
                        this.popup_spoc_data.push(spoc_distinct[i]);
                        unique[spoc_distinct[i].identity] = 1;
                    }
                }
            }
            if (selected_level === "Yet To Connect" || selected_level === "Total" || selected_level === "Invalid") {
                this.popup_spoc_data = spoc_distinct;
            }
            if (selected_level === "Yet To Connect") {
                this.spoc_data_popup = false;
                this.yet_to_connect_popup = true;

            }
            if (selected_level !== "Yet To Connect") {
                this.spoc_data_popup = true;
            }
            // document.getElementById("yet_to_connect_width").children[1].style.width = "60%";

            // document.getElementById("spoc_popup_width").children[1].style.width = "80%";
           
            console.log("popup_spoc_data", this.popup_spoc_data)
        },
        getExEdDisplayLevel(raw_level) {
            let level = this.exed_level_mapping[raw_level];
            if (level === undefined) {
                level = "cold";
            }
            return level;
        },
        getDisplaySource(raw_source) {
            let source = this.source_mapping[raw_source];
            if (source === undefined || source === "") {
                source = "other";
            }
            return source;
        },
       getSourceDataList(val) {
            console.log("val", val)
            this.rawdataselectedsource = val.name;
            this.getSourceData(val.name);
        },
        getSourceData(val){
           let newsource = "";
           newsource = val;
            this.raw_object_data = [];
            
                
                this.raw_data.forEach(rawSource => {  
                    // console.log("val", rawSource)  
                    if(newsource === rawSource.source) {
                        this.raw_object_data.push(rawSource)
                        
                    }
                    if(newsource === "All") {
                        this.raw_object_data.push(rawSource)
                    }
                    
                });
        },
        // csvObjectData() {
        //     this.source_stats.forEach(source => {
                
                
        //     });
        // },
        exportCsv(data) {
            console.log("csv", data);
            
            let csvContent = "data:text/csv;charset=utf-8,";
            data.forEach(function (rowArray) {
               
                const propertyValues = Object.values(rowArray);
                // const propertyKeys = Object.keys(rowArray);
                console.log("rowArray", propertyValues);
                let row = propertyValues;
                // let cloumn = propertyKeys;
                csvContent += row + "\r\n"; // add carriage return
            });
            var encodedUri = encodeURI(csvContent);
            var link = document.createElement("a");
            link.setAttribute("href", encodedUri);
            link.setAttribute("download", "fileName.csv");
            document.body.appendChild(link);
            link.click();
        },
    },
    components: {
        //   JsonCSV
        VueJsonToCsv,
    },
};
</script>
<style>
.spoc_popup_width_source_stats .vs-popup {
  min-width: 80%;
}
</style>
