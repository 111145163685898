<template>
    <div style="margin-top: 3%">
        <vs-table :data="spoc_stats">
            <template slot="header">
                <vs-row style="margin-bottom: 1%">
                    <vs-col vs-w="9">
                        <h3>SPOC Stats</h3>
                    </vs-col>
                    <!-- <vs-col vs-w="3" style="text-align: right">
                        <vue-json-to-csv
                            :json-data="spoc_stats"
                            :csv-title="'netenquiry spoc report'"
                        >
                            <vs-button size="small" color="dark"
                                >Export Spoc CSV</vs-button
                            >
                        </vue-json-to-csv>
                    </vs-col> -->
                </vs-row>
            </template>
            <template slot="thead">
                <vs-th> SPOC </vs-th>
                <vs-th> City Tier </vs-th>
                <vs-th> Total </vs-th>
                <vs-th> Unique </vs-th>
                <vs-th> Yet To Connect (NE)</vs-th>
                <vs-th> New Enquiry </vs-th>
                <vs-th> Re-Enquiry </vs-th>
                <vs-th> Invalid </vs-th>
                <vs-th> MHP </vs-th>
                <vs-th> P1 </vs-th>
                <vs-th> P2 </vs-th>
                <vs-th> P3 </vs-th>
                <vs-th> Enrolled </vs-th>
            </template>

            <template slot-scope="{ data }">
                <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                    <vs-td :data="data[indextr].name">
                        {{ data[indextr].name }}
                    </vs-td>
                    <vs-td :data="data[indextr].city_classification">
                        {{ data[indextr].city_classification }}
                    </vs-td>
                    <vs-td :data="data[indextr].total">
                        <a><p @click="passDataToPopup(tr, 'Total')">{{ data[indextr].total }}</p></a>
                    </vs-td>

                    <vs-td :data="data[indextr].unduplicated">
                        <a><p @click="passDataToPopup(tr, 'Unduplicated')">{{ data[indextr].unduplicated }}</p></a>
                    </vs-td>

                    <vs-td :data="data[indextr].untouched">
                        <a><p @click="passDataToPopup(tr, 'Yet To Connect')">{{ data[indextr].untouched }}</p></a>
                    </vs-td>

                    <vs-td :data="data[indextr].mhp">
                        <a><p @click="passDataToPopup(tr, 'New Enquiry')">{{ data[indextr].new_enquiry }}</p></a>
                    </vs-td>
                    <vs-td :data="data[indextr].mhp">
                        <a><p @click="passDataToPopup(tr, 'Re Enquiry')"> {{ data[indextr].re_enquiry }}</p></a>
                    </vs-td>

                    <vs-td :data="data[indextr].invalid">
                        <a><p @click="passDataToPopup(tr, 'Invalid')">{{ data[indextr].invalid }}</p></a>
                    </vs-td>

                    <vs-td :data="data[indextr].mhp">
                        <a><p @click="passDataToPopup(tr, 'MHP')">{{ data[indextr].mhp }}</p></a>
                    </vs-td>

                    <vs-td :data="data[indextr].P1">
                        <a><p @click="passDataToPopup(tr, 'P1')"> {{ data[indextr].P1 }}</p></a>
                    </vs-td>

                    <vs-td :data="data[indextr].P2">
                        <a><p @click="passDataToPopup(tr, 'P2')">{{ data[indextr].P2 }}</p></a>
                    </vs-td>

                    <vs-td :data="data[indextr].P3">
                        <a><p @click="passDataToPopup(tr, 'P3')">  {{ data[indextr].P3 }}</p></a>
                    </vs-td>

                    <vs-td :data="data[indextr].enrolled">
                        <a><p @click="passDataToPopup(tr, 'Enrolled')">{{ data[indextr].enrolled }}</p></a>
                    </vs-td>

                    
                </vs-tr>
            </template>
        </vs-table>
        <vs-popup class="cm_spoc_stats"  style="z-index: 53000"  :title="spoc_popup_title" :active.sync="spoc_data_popup">
            <p><b>Spoc Count : {{popup_spoc_data.length}}</b></p>
            <div>
            <vs-table max-items="10" pagination :data="popup_spoc_data">
                <template slot="header">
                    <vs-row style="margin-bottom: 1%">
                    </vs-row>
                </template>
                <template slot="thead">
                    <vs-th> identity </vs-th>
                    <vs-th> Name </vs-th>
                    <vs-th> Current Spoc </vs-th>
                    <vs-th> Source </vs-th>
                    <vs-th> Course </vs-th>
                    <vs-th> M Level </vs-th>
                    <vs-th> U Level </vs-th>
                    <vs-th> Enquiry Date </vs-th>
                    <vs-th> View Info </vs-th>
                </template>

                <template slot-scope="{ data }">
                    <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                        <vs-td :data="data[indextr].identity">
                            {{ data[indextr].identity }}
                        </vs-td>
                        <vs-td :data="data[indextr].person_name">
                            {{ data[indextr].person_name }}
                        </vs-td>

                        <vs-td :data="data[indextr].current_spoc">
                            {{ data[indextr].current_spoc }}
                        </vs-td>

                        <vs-td :data="data[indextr].source">
                            {{ data[indextr].source }}
                        </vs-td>

                        <vs-td :data="data[indextr].course">
                            {{ data[indextr].course }}
                        </vs-td>

                        <vs-td :data="data[indextr].level">
                            {{ data[indextr].level }}
                        </vs-td>
                        <vs-td :data="data[indextr].u_level">
                            {{ data[indextr].u_level }}
                        </vs-td>
                        <vs-td :data="data[indextr].enquiry_date">
                            {{ data[indextr].enquiry_date }}
                        </vs-td>
                        <vs-td v-if="data[indextr].mwb_id != null && data.mwb_id != ''">
                            <div @click="openPopupEmit(tr)">
                                <vs-icon
                                icon="info_outline"
                                size="small"
                                color="dark"
                                ></vs-icon>
                            </div>
                        </vs-td>
                        <vs-td v-else>
                        </vs-td>
                    </vs-tr>
                </template>
            </vs-table>
            </div>
        </vs-popup>
        <vs-popup class="holamundo" id="yet_to_connect_width"  :title="spoc_popup_title" :active.sync="yet_to_connect_popup">
            <p><b>Spoc Count : {{popup_spoc_data.length}}</b></p>
            <div>
            <vs-table max-items="10" pagination :data="popup_spoc_data">
                <template slot="header">
                    <vs-row style="margin-bottom: 1%">
                    </vs-row>
                </template>
                <template slot="thead">
                    <vs-th> Name </vs-th>
                    <vs-th> Course </vs-th>
                    <vs-th> Source </vs-th>
                    <vs-th> Campaign </vs-th>
                    <vs-th> Enquiry Date </vs-th>
                </template>

                <template slot-scope="{ data }">
                    <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                        <vs-td :data="data[indextr].name">
                            {{ data[indextr].name }}
                        </vs-td>
                        <vs-td :data="data[indextr].course">
                            {{ data[indextr].course }}
                        </vs-td>
                        <vs-td :data="data[indextr].source">
                            {{ data[indextr].source }}
                        </vs-td>
                        <vs-td :data="data[indextr].campaign">
                            {{ data[indextr].campaign }}
                        </vs-td>
                         <vs-td :data="data[indextr].enquiry_date">
                            {{ data[indextr].enquiry_date }}
                        </vs-td>
                    </vs-tr>
                </template>
            </vs-table>
            </div>
        </vs-popup>
    </div>
</template>

<script>
import moment from "moment";
import VueJsonToCsv from "vue-json-to-csv";
export default {
    props: ["spoc_stats","raw_data","selected_source"],
    data(){
        return{
            yet_to_connect_popup: false,
            spoc_data_popup:false,
            popup_spoc_data: [],
            source_mapping: {
                "website": "website",
                "-webinar": "website",
                "Chak De CPA": "MAC Webinar",
                "CMA Alumni Speak": "MAC Webinar",
                "CMA Gully Boy": "MAC Webinar",
                "CPA Alumni Speak": "MAC Webinar",
                Discovery: "Discovery",
                Email: "Email",
                Facebook: "Facebook",
                Meta: "Meta",
                SuperBot: "SuperBot",
                LMS: "LMS",
                google: "Google",
                "Incoming Call": "Incoming Call",
                "Incoming Call -SM": "Incoming Call",
                "Incoming Calls": "Incoming Call",
                "Incoming Calls -SM": "Incoming Call",
                IncomingCall: "Incoming Call",
                "Info Id -SM": "Info Id -SM",
                Instagram: "Instagram",
                IR: "IR Team",
                "JAVA-FS": "JAVA-FS",
                LeadSquared: "LeadSquared",
                Linkedin: "Linkedin",
                LiveChat: "Live Chat",
                "Live Chat": "Live Chat",
                Naukari: "Naukri Learning",
                Naukri: "Naukri Learning",
                "Naukri Learning": "Naukri Learning",
                None: "other",
                Organic: "Organic",
                "Organic -SM": "Organic -SM",
                Quora: "Quora",
                "Quora -SM": "Quora",
                SMS: "SMS",
                Test: "other",
                Testing: "other",
                undefined: "other",
                Verloop: "Chat",
                Chat: "Chat",
                "Voice SMS": "Voice SMS",
                Webinar: "Webinar - Registered",
                "Webinar - Registered": "Webinar - Registered",
                Website: "Website",
                "Website Chat": "Chat",
                "Website Form": "Website Form",
                "Website Search": "Website Form",
                "Website Whatsapp": "Website Whatsapp",
                "Wesbite Whatsapp": "Website Whatsapp",
                WhatsApp: "Whatsapp",
                YouTube: "YouTube",
                Google: "Google",
                Youtube: "YouTube",
                LinkedIn: "Linkedin",
                "Business Partner": "other",
                "Naukri-MR": "Naukri-MR",
                SquadIQ: "other",
                "Webinar-WAI": "Webinar - Registered",
                Wiley: "Wiley",
                "gupshup" : "Gupshup",
                "Gupshup" : "Gupshup"
            },
            cm_level_mapping: {
                M6: "P1",
                M5: "P1",
                "M3++": "P1",
                "M3+": "P1",
                M3: "P1",
                M4: "P2",
                M2: "P2",
                "M4-": "P3",
                M1: "P3",
                // "M2 - Did not Visit & Postponed": "P3",
            },
            spoc_popup_title: "",
        }
    },
    components: {
        //   JsonCSV
        VueJsonToCsv,
    },
    mounted(){
    },
    methods:{
        checkCity(popup_spoc_data_row, spoc_distinct, element){
            if(popup_spoc_data_row.spoc_city == ""){
                spoc_distinct = this.filterCcf(popup_spoc_data_row, spoc_distinct, element);
            }
            if(popup_spoc_data_row.city == ""){
                spoc_distinct =  this.spocFilterCcf(popup_spoc_data_row, spoc_distinct, element);
            }
            if(popup_spoc_data_row.spoc_city == "" && popup_spoc_data_row.city == ""){
                spoc_distinct.push(element);
            }

        },
        filterCcf(popup_spoc_data_row, spoc_distinct, element){
            if(popup_spoc_data_row.city !== ""){
                if(popup_spoc_data_row.city.includes(element.city)){
                    if(popup_spoc_data_row.city_classification == element.city_classification){
                        if(popup_spoc_data_row.spoc_id.includes(element.spoc_id)){
                            spoc_distinct.push(element);
                        }
                    }
                }
            }
            return spoc_distinct;
        },
        spocFilterCcf(popup_spoc_data_row, spoc_distinct, element){
            if(popup_spoc_data_row.spoc_city !== ""){
                if(popup_spoc_data_row.spoc_city.includes(element.spoc_city)){
                    if(popup_spoc_data_row.city_classification == element.city_classification){
                        if(popup_spoc_data_row.spoc_id.includes(element.spoc_id)){
                            spoc_distinct.push(element);
                        }
                    }
                }
            }
            return spoc_distinct;
        },
        passDataToPopup(popup_spoc_data_row, selected_level){
            console.log("cm spoc_stats",popup_spoc_data_row, selected_level)
            this.spoc_popup_title = popup_spoc_data_row.name + ' ' + selected_level;
            let spoc_distinct = [];
            let person_ids = [];
            this.popup_spoc_data = [];
            let source_data = this.raw_data;
            for (let i = 0; i < source_data.length; i++) {
                let element = this.raw_data[i];
                let display_source = this.getDisplaySource(element.source);
                // let selected_popup_source = this.getDisplaySource(popup_spoc_data_row.source);
                let level = this.getCMDisplayLevel(element.level);
                    if(this.selected_source !== "All"){
                        if(selected_level === "Unduplicated"){
                            if(this.selected_source.includes(display_source) && popup_spoc_data_row.name === element.current_spoc && !person_ids.includes(element.person_id)) {
                                // if(element.person_id !== null){
                                    // spoc_distinct.push(element);
                                    this.checkCity(popup_spoc_data_row, spoc_distinct, element);
                                    // if(popup_spoc_data_row.spoc_city == ""){
                                    //     spoc_distinct = this.filterCcf(popup_spoc_data_row, spoc_distinct, element);

                                    // }
                                    // if(popup_spoc_data_row.city == ""){
                                    //     spoc_distinct = this.spocFilterCcf(popup_spoc_data_row, spoc_distinct, element);
                                    // }
                                    // if(popup_spoc_data_row.spoc_city.length == "" && popup_spoc_data_row.city == ""){
                                    //     spoc_distinct.push(element);
                                    // }
                                    this.spoc_data_popup = true;
                                // }
                            } 
                            person_ids.push(element.person_id);
                        }
                        if(selected_level === "New Enquiry"){
                        let lead_added_timestamp = moment(element.lead_added_date).unix();
                            if(this.selected_source.includes(display_source) && popup_spoc_data_row.name === element.current_spoc && element.person_id !== null && element.matched === 1 && lead_added_timestamp > element.enquiry_date_unix) {
                                // spoc_distinct.push(element);
                                
                                 this.checkCity(popup_spoc_data_row, spoc_distinct, element);
                                this.spoc_data_popup = true;
                            } 
                        }
                        if(selected_level === "Re Enquiry"){
                        let lead_added_timestamp = moment(element.lead_added_date).unix();
                            if(this.selected_source.includes(display_source) && element.person_id !== null && element.matched === 1 && lead_added_timestamp <= element.enquiry_date_unix ) {
                                // spoc_distinct.push(element);
                                
                                 this.checkCity(popup_spoc_data_row, spoc_distinct, element);
                                this.spoc_data_popup = true;
                            }   
                        }
                        if(selected_level === "Total"){
                            // console.log("selected_popup_source",selected_popup_source)
                            if(this.selected_source.includes(display_source) &&  popup_spoc_data_row.name === element.current_spoc) {
                                // console.log("selected_popup_source not all", element.city);
                                
                                 this.checkCity(popup_spoc_data_row, spoc_distinct, element);
                                this.spoc_data_popup = true;
                            } 
                        }
                        if(selected_level === "P1" || selected_level === "P2" || selected_level === "P3"){
                            if (this.selected_source.includes(display_source) && selected_level === level && popup_spoc_data_row.name === element.current_spoc) {
                                // spoc_distinct.push(element);
                                
                                this.checkCity(popup_spoc_data_row, spoc_distinct, element);
                                this.spoc_data_popup = true;
                            }
                        }
                        if(selected_level === "Enrolled"){
                            if(element.enrolled === 1 && this.selected_source.includes(display_source) && popup_spoc_data_row.name === element.current_spoc) {
                                // spoc_distinct.push(element);
                                
                                 this.checkCity(popup_spoc_data_row, spoc_distinct, element);
                                this.spoc_data_popup = true;
                            } 
                        }
                        if(selected_level === "Invalid"){
                            if(element.invalid_net_enquiry === 1 && this.selected_source.includes(display_source) && popup_spoc_data_row.name === element.current_spoc) {
                                console.log("Invalid", element.source);
                                // spoc_distinct.push(element);
                               
                                 this.checkCity(popup_spoc_data_row, spoc_distinct, element);
                                this.spoc_data_popup = true;
                            } 
                        }
                        if(selected_level === "MHP"){
                            if(element.mhp_status === 1 && this.selected_source.includes(display_source) && popup_spoc_data_row.name === element.current_spoc) {
                                console.log("mhp check", element.source);
                                // spoc_distinct.push(element);
                                
                                 this.checkCity(popup_spoc_data_row, spoc_distinct, element);
                            } 
                        }
                        if(selected_level === "Yet To Connect"){
                            if (element.invalid_net_enquiry === 0 && element.matched === 0 && this.selected_source.includes(display_source) && popup_spoc_data_row.name === element.current_spoc) {
                                // spoc_distinct.push(element);
                                
                                 this.checkCity(popup_spoc_data_row, spoc_distinct, element);
                                this.yet_to_connect_popup = true;
                            }
                        }
                    }
                    if(this.selected_source === "All"){
                        if(selected_level === "Unduplicated"){
                            if(popup_spoc_data_row.name === element.current_spoc && !person_ids.includes(element.person_id)) {
                                // if(element.person_id !== null){
                                    // spoc_distinct.push(element);
                               
                                 this.checkCity(popup_spoc_data_row, spoc_distinct, element);
                                    // this.spoc_data_popup = true;
                                // }
                            } 
                            person_ids.push(element.person_id);
                        }
                        if(selected_level === "New Enquiry"){
                        let lead_added_timestamp = moment(element.lead_added_date).unix();
                            if(popup_spoc_data_row.name === element.current_spoc && element.person_id !== null && element.matched === 1 && lead_added_timestamp > element.enquiry_date_unix) {
                                // spoc_distinct.push(element);
                                
                                 this.checkCity(popup_spoc_data_row, spoc_distinct, element);
                                // this.spoc_data_popup = true;
                            } 
                        }
                        if(selected_level === "Re Enquiry"){
                        let lead_added_timestamp = moment(element.lead_added_date).unix();
                            if(element.person_id !== null && element.matched === 1 && lead_added_timestamp <= element.enquiry_date_unix && popup_spoc_data_row.name === element.current_spoc) {
                                // spoc_distinct.push(element);
                                
                                 this.checkCity(popup_spoc_data_row, spoc_distinct, element);
                                // this.spoc_data_popup = true;
                            }   
                        }
                        if(selected_level === "Total"){
                            if(popup_spoc_data_row.name === element.current_spoc) {
                                // spoc_distinct.push(element);
                                
                                 this.checkCity(popup_spoc_data_row, spoc_distinct, element);
                                // this.spoc_data_popup = true;
                            } 
                        }
                        if(selected_level === "P1" || selected_level === "P2" || selected_level === "P3"){
                            if (selected_level === level && popup_spoc_data_row.name === element.current_spoc) {
                                // spoc_distinct.push(element);
                                
                                 this.checkCity(popup_spoc_data_row, spoc_distinct, element);
                                // this.spoc_data_popup = true;
                            }
                        }
                        if(selected_level === "Enrolled"){
                            if(element.enrolled === 1 && popup_spoc_data_row.name === element.current_spoc) {
                                // spoc_distinct.push(element);
                                
                                 this.checkCity(popup_spoc_data_row, spoc_distinct, element);
                                // this.spoc_data_popup = true;
                            } 
                        }
                        if(selected_level === "Invalid"){
                            if(element.invalid_net_enquiry === 1  && popup_spoc_data_row.name === element.current_spoc) {
                                console.log("Invalid", element.source);
                                // spoc_distinct.push(element);
                                
                                 this.checkCity(popup_spoc_data_row, spoc_distinct, element);
                                // this.spoc_data_popup = true;
                            } 
                        }
                        if(selected_level === "MHP"){
                            if(element.mhp_status === 1  && popup_spoc_data_row.name === element.current_spoc) {
                                console.log("mhp check", element.source);
                                // spoc_distinct.push(element);
                                
                                this.checkCity(popup_spoc_data_row, spoc_distinct, element);
                            } 
                        }
                        if(selected_level === "Yet To Connect"){
                            if (element.invalid_net_enquiry === 0 && element.matched === 0 && popup_spoc_data_row.name === element.current_spoc) {
                                // spoc_distinct.push(element);
                                
                                this.checkCity(popup_spoc_data_row, spoc_distinct, element);
                                this.yet_to_connect_popup = true;
                            }
                        }
                    }
            }
            // console.log("Yet to connect",spoc_distinct);
            let unique = [];
            if(selected_level !== "Yet To Connect" && selected_level !== "Total" && selected_level !== "Invalid"){
                for( let i = 0; i < spoc_distinct.length; i++ ){
                    if( !unique[spoc_distinct[i].identity]){
                        this.popup_spoc_data.push(spoc_distinct[i]);
                        unique[spoc_distinct[i].identity] = 1;
                    }
                }
            }
            if(selected_level === "Yet To Connect" || selected_level === "Total" || selected_level === "Invalid"){
                this.popup_spoc_data = spoc_distinct;
            }
            if(selected_level === "Yet To Connect"){
                this.spoc_data_popup = false;
                this.yet_to_connect_popup = true;
                
            }
            if(selected_level !== "Yet To Connect"){
                this.spoc_data_popup = true;
            }
            
            document.getElementById("yet_to_connect_width").children[1].style.width = "60%";

            // document.getElementById("spoc_popup_width").children[1].style.width = "80%";
            console.log("popup_spoc_data",this.popup_spoc_data)
        },
        openPopupEmit(payload) {
            this.openBigPopup(payload.mwb_id);
        },
        getDisplaySource(raw_source) {
            if(raw_source != null){
                let position  = raw_source.length - 8;
                let check_word = "";
                check_word = raw_source.substring(position);
                if(check_word == '-webinar'){
                raw_source = "";
                raw_source = check_word;
                }
            }
            let source = this.source_mapping[raw_source];
            if (source === undefined || source === "") {
                source = "other";
            }
            return source;
        },
        getCMDisplayLevel(cm_level) {
            let cmLevel = this.cm_level_mapping[cm_level];
            if (cmLevel === undefined) {
                cmLevel = "cold";
            }
            return cmLevel;
        },
    }
};
</script>
<style>
.cm_spoc_stats .vs-popup {
  min-width: 80%;
}
</style>
