<template>
    <div style="margin-top: 3%">
        <vs-table :data="source_stats">
            <template slot="header">
                <vs-row style="margin-bottom: 1%;">
                    <vs-col vs-w="9">
                        <h3>Source Stats</h3>
                    </vs-col>
                </vs-row>
            </template>
            <template slot="thead">
                <vs-th></vs-th>
                <vs-th> Source </vs-th>
                <vs-th> Total </vs-th>
                <vs-th> Unique </vs-th>
                <vs-th> Yet To Connect (NE) </vs-th>
                <vs-th> New Enquiry </vs-th>
                <vs-th> Re-Enquiry </vs-th>
                <vs-th> Invalid </vs-th>
                <vs-th> MHP </vs-th>
                <vs-th> P1 </vs-th>
                <vs-th> P2 </vs-th>
                <vs-th> P3 </vs-th>
                <vs-th> Enrolled </vs-th>
                
                <vs-th v-if="userid === 132 || userid === 122 || userid === 39 || userid === 977 || userid === 343 || userid === 143 || userid === 1344 || userid === 1736"> Action </vs-th>
            </template>

            <template slot-scope="{ data }">
                <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                    <vs-td >
                        <vs-checkbox v-model="selected" :vs-value="tr" ></vs-checkbox>   
                    </vs-td>
                    <vs-td v-if="data[indextr].name == 'other'" :data="data[indextr].name">
                        Others
                    </vs-td>
                    <vs-td v-else :data="data[indextr].name">
                        {{ data[indextr].name }}
                    </vs-td>
                    

                    <vs-td :data="data[indextr].total">
                       <a><p @click="passDataToPopup(tr, 'Total')"> {{ data[indextr].total }} </p></a>
                    </vs-td>

                    <vs-td :data="data[indextr].unduplicated">
                        <a><p @click="passDataToPopup(tr, 'Unduplicated')"> {{ data[indextr].unduplicated }}</p></a>
                    </vs-td>

                    <vs-td :data="data[indextr].untouched">
                        <!-- {{ data[indextr].untouched }} -->
                        <a><p @click="passDataToPopup(tr, 'Yet To Connect')"> {{ data[indextr].untouched }}</p></a>
                    </vs-td>

                    <vs-td :data="data[indextr].new_enquiry">
                        <a><p @click="passDataToPopup(tr, 'New Enquiry')"> {{ data[indextr].new_enquiry }} </p></a>
                    </vs-td>
                    <vs-td :data="data[indextr].re_enquiry">
                        <a><p @click="passDataToPopup(tr, 'Re Enquiry')"> {{ data[indextr].re_enquiry }}</p></a>
                    </vs-td>

                    <vs-td :data="data[indextr].invalid">
                        <a><p @click="passDataToPopup(tr, 'Invalid')">{{ data[indextr].invalid }}</p></a>
                    </vs-td>

                    <vs-td :data="data[indextr].mhp">
                        <!-- {{ data[indextr].mhp }} -->
                        <a><p @click="passDataToPopup(tr, 'MHP')"> {{ data[indextr].mhp }}</p></a>
                    </vs-td>
                      

                    <vs-td :data="data[indextr].P1">
                        <a><p @click="passDataToPopup(tr, 'P1')"> {{ data[indextr].P1 }}</p></a>
                    </vs-td>

                    <vs-td :data="data[indextr].P2">
                        <a><p @click="passDataToPopup(tr, 'P2')"> {{ data[indextr].P2 }}</p></a>
                    </vs-td>
                    <vs-td :data="data[indextr].P3">
                        <a><p @click="passDataToPopup(tr, 'P3')"> {{ data[indextr].P3 }}</p></a>
                    </vs-td>

                    <vs-td :data="data[indextr].enrolled">
                        <a><p @click="passDataToPopup(tr, 'Enrolled')"> {{ data[indextr].enrolled }}</p></a>
                    </vs-td>

                    <vs-td :data="data[indextr].mhp" v-if="userid === 132 || userid === 122 || userid === 39 || userid === 977 || userid === 343 || userid === 143 || userid === 1344 || userid === 1736">
                        
                                    
                                    <vue-json-to-csv
                                        :json-data="raw_object_data"
                                        :csv-title="
                                            'netenquiryreport' + '-' + startdatecsv + '-' + enddatecsv + '-' + rawdataselectedsource
                                        "
                                    >
                                        <vs-button type="filled" color="dark" @click="getSourceDataList(tr)"
                                            >Export CSV</vs-button
                                        >
                                    </vue-json-to-csv>
                    </vs-td>
                </vs-tr>
            </template>
        </vs-table>
        <pre>{{ selected_sources }}</pre>
        <vs-popup class="cm_source_stats"  style="z-index: 53000"  :title="spoc_popup_title" :active.sync="spoc_data_popup">
            <p><b>Spoc Count : {{popup_spoc_data.length}}</b></p>
            <div>
            <vs-table max-items="10" pagination :data="this.popup_spoc_data">
                <template slot="header">
                    <vs-row style="margin-bottom: 1%">
                    </vs-row>
                </template>
                <template slot="thead">
                    <vs-th> identity </vs-th>
                    <vs-th> Name </vs-th>
                    <vs-th> Current Spoc </vs-th>
                    <vs-th> Source </vs-th>
                    <vs-th> Course </vs-th>
                    <vs-th> M Level </vs-th>
                    <vs-th> U Level </vs-th>
                    <vs-th> Enquiry Date </vs-th>
                    <vs-th> View Info </vs-th>
                </template>

                <template slot-scope="{ data }">
                    <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                        <vs-td :data="data[indextr].identity">
                            {{ data[indextr].identity }}
                        </vs-td>
                        <vs-td :data="data[indextr].person_name">
                            {{ data[indextr].person_name }}
                        </vs-td>

                        <vs-td :data="data[indextr].current_spoc">
                            {{ data[indextr].current_spoc }}
                        </vs-td>

                        <vs-td :data="data[indextr].source">
                            {{ data[indextr].source }}
                        </vs-td>

                        <vs-td :data="data[indextr].course">
                            {{ data[indextr].course }}
                        </vs-td>

                        <vs-td :data="data[indextr].level">
                            {{ data[indextr].level }}
                        </vs-td>
                        <vs-td :data="data[indextr].u_level">
                            {{ data[indextr].u_level }}
                        </vs-td>
                        <vs-td :data="data[indextr].enquiry_date">
                            {{ data[indextr].enquiry_date }}
                        </vs-td>
                        <vs-td v-if="data[indextr].mwb_id != null && data.mwb_id != ''">
                            <div @click="openPopupEmit(tr)">
                                <vs-icon
                                icon="info_outline"
                                size="small"
                                color="dark"
                                ></vs-icon>
                            </div>
                        </vs-td>
                        <vs-td v-else>
                        </vs-td>
                    </vs-tr>
                </template>
            </vs-table>
            </div>
        </vs-popup>
        <vs-popup class="holamundo" id="yet_to_connect_width"  :title="spoc_popup_title" :active.sync="yet_to_connect_popup">
            <p><b>Spoc Count : {{popup_spoc_data.length}}</b></p>
            <div>
            <vs-table max-items="10" pagination :data="this.popup_spoc_data">
                <template slot="header">
                    <vs-row style="margin-bottom: 1%">
                    </vs-row>
                </template>
                <template slot="thead">
                    <vs-th> Name </vs-th>
                    <vs-th> Course </vs-th>
                    <vs-th> Source </vs-th>
                    <vs-th> Campaign </vs-th>
                    <vs-th> Enquiry Date </vs-th>
                </template>

                <template slot-scope="{ data }">
                    <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                        <vs-td :data="data[indextr].name">
                            {{ data[indextr].name }}
                        </vs-td>
                        <vs-td :data="data[indextr].course">
                            {{ data[indextr].course }}
                        </vs-td>
                        <vs-td :data="data[indextr].source">
                            {{ data[indextr].source }}
                        </vs-td>
                        <vs-td :data="data[indextr].campaign">
                            {{ data[indextr].campaign }}
                        </vs-td>
                         <vs-td :data="data[indextr].enquiry_date">
                            {{ data[indextr].enquiry_date }}
                        </vs-td>
                    </vs-tr>
                </template>
            </vs-table>
            </div>
        </vs-popup>
    </div>
</template>

<script>
// import JsonCSV from 'vue-json-csv'

// Vue.component('downloadCsv', JsonCSV)
import moment from "moment";
import VueJsonToCsv from "vue-json-to-csv";
export default {
    data: () => ({
        yet_to_connect_popup: false,
        spoc_popup_title: "",
        popup_spoc_data: [],
        spoc_data_popup: false,
        selected: [],
        selected_sources: "",
        raw_object_data: [],
        source_popup: false,
        rawdataselectedsource: "",
        source_mapping: {
        "website": "website",
        "-webinar": "website",
        "Chak De CPA": "MAC Webinar",
        "CMA Alumni Speak": "MAC Webinar",
        "CMA Gully Boy": "MAC Webinar",
        "CPA Alumni Speak": "MAC Webinar",
        Discovery: "Discovery",
        Email: "Email",
        Facebook: "Facebook",
        Meta: "Meta",
        SuperBot: "SuperBot",
        LMS: "LMS",
        google: "Google",
        "Incoming Call": "Incoming Call",
        "Incoming Call -SM": "Incoming Call",
        "Incoming Calls": "Incoming Call",
        "Incoming Calls -SM": "Incoming Call",
        IncomingCall: "Incoming Call",
        "Info Id -SM": "Info Id -SM",
        Instagram: "Instagram",
        IR: "IR Team",
        "JAVA-FS": "JAVA-FS",
        LeadSquared: "LeadSquared",
        Linkedin: "Linkedin",
        LiveChat: "Live Chat",
        "Live Chat": "Live Chat",
        Naukari: "Naukri Learning",
        Naukri: "Naukri Learning",
        "Naukri Learning": "Naukri Learning",
        None: "other",
        Organic: "Organic",
        "Organic -SM": "Organic -SM",
        Quora: "Quora",
        "Quora -SM": "Quora",
        SMS: "SMS",
        Test: "other",
        Testing: "other",
        undefined: "other",
        Verloop: "Chat",
        Chat: "Chat",
        "Voice SMS": "Voice SMS",
        Webinar: "Webinar - Registered",
        "Webinar - Registered": "Webinar - Registered",
        Website: "Website",
        "Website Chat": "Chat",
        "Website Form": "Website Form",
        "Website Search": "Website Form",
        "Website Whatsapp": "Website Whatsapp",
        "Wesbite Whatsapp": "Website Whatsapp",
        WhatsApp: "WhatsApp",
        YouTube: "YouTube",
        Google: "Google",
        Youtube: "YouTube",
        LinkedIn: "Linkedin",
        "Business Partner": "other",
        "Naukri-MR": "Naukri-MR",
        SquadIQ: "other",
        "Webinar-WAI": "Webinar - Registered",
        Wiley: "Wiley",
        "gupshup" : "Gupshup",
        "Gupshup" : "Gupshup"
      },
      cm_level_mapping: {
        M6: "P1",
        M5: "P1",
        "M3++": "P1",
        "M3+": "P1",
        M3: "P1",
        M4: "P2",
        M2: "P2",
        "M4-": "P3",
        M1: "P3",
        // "M2 - Did not Visit & Postponed": "P3",
      },
    }),
    props: ["source_stats", "raw_data", "enddatecsv", "startdatecsv",'userid', 'start_date'],
    watch: {
        selected: function () {
            console.log("val", this.selected)
            this.rawdataselectedsource = "";
            this.selected_sources = "";
            let sources = [];
            for (let i = 0; i < this.selected.length; i++) {
                const source = this.selected[i];
                if (source.name === "All") {
                    this.rawdataselectedsource = "All";
                    this.selected_sources = "All";
                    this.selected = this.source_stats;
                    break;
                } else {
                    this.rawdataselectedsource = source.name;
                    sources.push(source.name);
                }
            }
            if (this.selected_sources !== "All") {
                this.selected_sources = sources.join();
            }
            
            this.$emit("source_changed", this.selected_sources);
            console.log("selected_sources",this.selected_sources);
            
        },
    },
    methods: {
        checkCity(popup_spoc_data_row, spoc_distinct, element){
            if(popup_spoc_data_row.spoc_city == ""){
                spoc_distinct = this.filterCcf(popup_spoc_data_row, spoc_distinct, element);
            }
            if(popup_spoc_data_row.city == ""){
                spoc_distinct =  this.spocFilterCcf(popup_spoc_data_row, spoc_distinct, element);
            }
            if(popup_spoc_data_row.spoc_city == "" && popup_spoc_data_row.city == ""){
                spoc_distinct.push(element);
            }

        },
        filterCcf(popup_spoc_data_row, spoc_distinct, element){
            // if(popup_spoc_data_row.city === "" || popup_spoc_data_row.city_classification === "" || popup_spoc_data_row.spoc_id === ""){
            //     spoc_distinct.push(element);
            // }
            if(popup_spoc_data_row.city !== ""){
                if(popup_spoc_data_row.city.includes(element.city)){
                    if(popup_spoc_data_row.city_classification.includes(element.city_classification)){
                        if(popup_spoc_data_row.spoc_id.includes(element.spoc_id)){
                            spoc_distinct.push(element);
                        }
                    }
                }
            }
            return spoc_distinct;
        },
        spocFilterCcf(popup_spoc_data_row, spoc_distinct, element){
            // if(popup_spoc_data_row.spoc_city === "" || popup_spoc_data_row.city_classification === "" || popup_spoc_data_row.spoc_id === ""){
            //     spoc_distinct.push(element);
            // }
            if(popup_spoc_data_row.spoc_city !== ""){
                if(popup_spoc_data_row.spoc_city.includes(element.spoc_city)){
                    if(popup_spoc_data_row.city_classification.includes(element.city_classification)){
                        if(popup_spoc_data_row.spoc_id.includes(element.spoc_id)){
                            spoc_distinct.push(element);
                        }
                    }
                }
            }
            return spoc_distinct;
        },

        passDataToPopup(popup_spoc_data_row, selected_level){
            console.log("cm data",popup_spoc_data_row, selected_level);
            this.spoc_popup_title = popup_spoc_data_row.name + ' ' + selected_level;
            let spoc_distinct = [];
            let last_ten_digits = [];
            this.popup_spoc_data = [];
            let source_data = this.raw_data;
            for (let i = 0; i < source_data.length; i++) {
                let element = this.raw_data[i];
                let display_source = this.getDisplaySource(element.source);
                let selected_popup_source = this.getDisplaySource(popup_spoc_data_row.name);
                let level = this.getCMDisplayLevel(element.level);
                    if(popup_spoc_data_row.name !== "All"){
                        if(selected_level === "Unduplicated"){
                            if(display_source === selected_popup_source && !last_ten_digits.includes(element.last_ten_digits)) {
                                // if(element.person_id !== null){
                                    // spoc_distinct.push(element);
                                    this.checkCity(popup_spoc_data_row, spoc_distinct, element);
                                    // if(popup_spoc_data_row.spoc_city.length == ""){
                                    //     spoc_distinct = this.filterCcf(popup_spoc_data_row, spoc_distinct, element);
                                    // }
                                    // if(popup_spoc_data_row.city == ""){
                                    //    spoc_distinct =  this.spocFilterCcf(popup_spoc_data_row, spoc_distinct, element);
                                    // }
                                    // if(popup_spoc_data_row.spoc_city.length == "" && popup_spoc_data_row.city == ""){
                                    //     spoc_distinct.push(element);
                                    // }
                                // }
                            } 
                            last_ten_digits.push(element.last_ten_digits);
                        }
                        if(selected_level === "New Enquiry"){
                        let lead_added_timestamp = moment(element.lead_added_date).unix();
                        let selcted_date = this.convertToLocalTimestamp(this.start_date)
                        console.log("start dateee", this.start_date,  selcted_date);
                            if(display_source === selected_popup_source && element.matched === 1 && lead_added_timestamp >= selcted_date) {
                                
                                this.checkCity(popup_spoc_data_row, spoc_distinct, element);
                            } 
                        }
                        if(selected_level === "Re Enquiry"){
                        let lead_added_timestamp = moment(element.lead_added_date).unix();
                            if(display_source === selected_popup_source && element.matched === 1 && lead_added_timestamp <= element.enquiry_date_unix) {
                                
                                this.checkCity(popup_spoc_data_row, spoc_distinct, element);
                            } 
                        }
                        if(selected_level === "Total"){
                            if(display_source === selected_popup_source) {
                               
                                this.checkCity(popup_spoc_data_row, spoc_distinct, element);
                            } 
                        }
                        if(selected_level === "P1" || selected_level === "P2" || selected_level === "P3"){
                            if (display_source === selected_popup_source && selected_level === level) {
                                
                                this.checkCity(popup_spoc_data_row, spoc_distinct, element);
                            }
                        }
                        if(selected_level === "Enrolled"){
                            if(element.enrolled === 1 && display_source === selected_popup_source) {
                                
                                this.checkCity(popup_spoc_data_row, spoc_distinct, element);
                            } 
                        }
                        if(selected_level === "Invalid"){
                            if(element.invalid_net_enquiry === 1 && display_source === selected_popup_source) {
                                console.log("Invalid", element.source);
                                
                                this.checkCity(popup_spoc_data_row, spoc_distinct, element);
                            } 
                        }
                        if(selected_level === "MHP"){
                            if(element.mhp_status === 1 && display_source === selected_popup_source) {
                                console.log("mhp check", element.source);
                                
                                this.checkCity(popup_spoc_data_row, spoc_distinct, element);
                            } 
                        }
                        if(selected_level === "Yet To Connect"){
                            if (element.invalid_net_enquiry === 0 && element.matched === 0 && display_source === selected_popup_source) {
                                
                                this.checkCity(popup_spoc_data_row, spoc_distinct, element);
                            }
                        }
                    }
                    if(popup_spoc_data_row.name === "All"){
                        if(selected_level === "Unduplicated"){
                            if(!last_ten_digits.includes(element.last_ten_digits)) {
                                // if(element.person_id !== null){
                                    
                                    this.checkCity(popup_spoc_data_row, spoc_distinct, element);
                                // }
                            } 
                            last_ten_digits.push(element.last_ten_digits);
                        }
                        if(selected_level === "New Enquiry"){
                        let lead_added_timestamp = moment(element.lead_added_date).unix();
                        let selcted_date = this.convertToLocalTimestamp(this.start_date)
                            if(element.matched === 1 && lead_added_timestamp >= selcted_date) {
                               
                                this.checkCity(popup_spoc_data_row, spoc_distinct, element);
                                
                            } 
                        }
                        if(selected_level === "Re Enquiry"){
                        let lead_added_timestamp = moment(element.lead_added_date).unix();
                            if(element.matched === 1 && lead_added_timestamp <= element.enquiry_date_unix) {
                                
                                this.checkCity(popup_spoc_data_row, spoc_distinct, element);
                            } 
                        }
                        if(selected_level === "Total"){
                                
                               
                                this.checkCity(popup_spoc_data_row, spoc_distinct, element);
                        }

                        if(selected_level === "P1" || selected_level === "P2" || selected_level === "P3"){
                            if (selected_level === level) {
                                
                                this.checkCity(popup_spoc_data_row, spoc_distinct, element);
                            }
                        }
                        if(selected_level === "Enrolled"){
                            if(element.enrolled === 1) {
                                
                                this.checkCity(popup_spoc_data_row, spoc_distinct, element);
                            } 
                        }
                        if(selected_level === "MHP"){
                            if(element.mhp_status === 1) {
                                
                                this.checkCity(popup_spoc_data_row, spoc_distinct, element);
                            } 
                        }
                        if(selected_level === "Yet To Connect"){
                            if (element.invalid_net_enquiry === 0 && element.matched === 0) {
                                
                                this.checkCity(popup_spoc_data_row, spoc_distinct, element);
                            }
                        }
                        if(selected_level === "Invalid"){
                            if(element.invalid_net_enquiry === 1 ) {
                                console.log("Invalid", element.source);
                                
                                this.checkCity(popup_spoc_data_row, spoc_distinct, element);
                            } 
                        }
                    }
            }
            console.log("Yet to connect",spoc_distinct);
            let unique = [];
            if(selected_level !== "Yet To Connect" && selected_level !== "Total" && selected_level !== "Invalid"){
                for( let i = 0; i < spoc_distinct.length; i++ ){
                    if( !unique[spoc_distinct[i].identity]){
                        this.popup_spoc_data.push(spoc_distinct[i]);
                        unique[spoc_distinct[i].identity] = 1;
                    }
                }
            }
            if(selected_level === "Yet To Connect" || selected_level === "Total" || selected_level === "Invalid"){
                this.popup_spoc_data = spoc_distinct;
            }
            if(selected_level === "Yet To Connect"){
                this.spoc_data_popup = false;
                this.yet_to_connect_popup = true;
                
            }
            if(selected_level !== "Yet To Connect"){
                this.spoc_data_popup = true;
            }
            
            document.getElementById("yet_to_connect_width").children[1].style.width = "60%";

            // document.getElementById("spoc_popup_width").children[1].style.width = "80%";
        },
        openPopupEmit(payload) {
            this.openBigPopup(payload.mwb_id);
        },
        convertToLocalTimestamp(val){
            var d = new Date(val);
            d.setHours(0,0,0,0);
            return this.getDate(d.toLocaleString('en-US', { timeZone: 'Asia/Kolkata' }));
        },
        getDate(date) {
            var datum = Date.parse(date);
            return datum/1000;
        },
        getCMDisplayLevel(cm_level) {
            let cmLevel = this.cm_level_mapping[cm_level];
            if (cmLevel === undefined) {
                cmLevel = "cold";
            }
            return cmLevel;
        },
        getDisplaySource(raw_source) {
            if(raw_source != null){
                let position  = raw_source.length - 8;
                let check_word = "";
                check_word = raw_source.substring(position);
                if(check_word == '-webinar'){
                raw_source = "";
                raw_source = check_word;
                }
            }
            let source = this.source_mapping[raw_source];
            if (source === undefined || source === "") {
                source = "other";
            }
            return source;
        },
        getSourceDataList(val) {
            console.log("val", val)
            this.rawdataselectedsource = val.name;
            this.getSourceData(val.name);
        },
        getSourceData(val){
           let newsource = "";
           newsource = val;
            this.raw_object_data = [];
            
                
                this.raw_data.forEach(rawSource => {  
                    // console.log("val", rawSource)  
                    if(newsource === rawSource.source) {
                        this.raw_object_data.push(rawSource)
                        
                    }
                    if(newsource === "All") {
                        this.raw_object_data.push(rawSource)
                    }
                    
                });
        },
        // csvObjectData() {
        //     this.source_stats.forEach(source => {
                
                
        //     });
        // },
        exportCsv(data) {
            console.log("csv", data);
            
            let csvContent = "data:text/csv;charset=utf-8,";
            data.forEach(function (rowArray) {
               
                const propertyValues = Object.values(rowArray);
                // const propertyKeys = Object.keys(rowArray);
                console.log("rowArray", propertyValues);
                let row = propertyValues;
                // let cloumn = propertyKeys;
                csvContent += row + "\r\n"; // add carriage return
            });
            var encodedUri = encodeURI(csvContent);
            var link = document.createElement("a");
            link.setAttribute("href", encodedUri);
            link.setAttribute("download", "fileName.csv");
            document.body.appendChild(link);
            link.click();
        },
    },
    components: {
        //   JsonCSV
        VueJsonToCsv,
    },
};
</script>
<style>
.cm_source_stats .vs-popup {
  min-width: 80%;
}
</style>
